import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    Chip,
    Paper,
    Collapse,
    IconButton,
    Fade,
    Stack,
    Portal,
    Switch,
    FormControlLabel,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material';
import Draggable from 'react-draggable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TimerIcon from '@mui/icons-material/Timer';
import PsychologyIcon from '@mui/icons-material/Psychology';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Quiz, QuizQuestion } from '../Constants/interface';
import { fetchQuiz } from './Services/firebaseService';
import { ANALYTICS_CATEGORIES, logEvent, logCustomEvent } from '../utils/Analytics';
import { styled } from '@mui/material/styles';

interface SummaryQuizProps {
    lectureId: string;
    onTimeStampClick?: (timeStamp: number) => void;
    transcriptionText?: string[];
}


const QuestionCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    overflow: 'visible',
    transition: 'all 0.3s ease',
    background: 'linear-gradient(145deg, #f0f0f0 0%, #e6e6e6 100%)',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1),
    },
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 8px 30px rgba(0,0,0,0.08)',
    },
}));

const OptionButton = styled(Button)(({ theme }) => ({
    transition: 'all 0.2s ease',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.spacing(1),
    background: '#f0f0f0',
    '&:hover': {
        transform: 'translateX(-4px)',
        background: 'linear-gradient(90deg, #e8e8e8 0%, #f0f0f0 100%)',
    },
}));

const TranscriptionSection = styled(Paper)(({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    maxHeight: '200px',
    overflowY: 'auto',
    border: `1px solid ${theme.palette.divider}`,
}));

const FloatingQuizCard = styled(Card)(({ theme }) => ({
    position: 'fixed',
    top: '50%',
    right: theme.spacing(3),
    transform: 'translateY(-50%)',
    width: '450px',
    height: 'auto',
    minHeight: '300px',
    maxHeight: '80vh',
    maxWidth: 'calc(100vw - 48px)',
    zIndex: theme.zIndex.modal,
    background: 'linear-gradient(145deg, #f0f0f0 0%, #e6e6e6 100%)',
    boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
    border: '1px solid rgba(0,0,0,0.08)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        right: theme.spacing(2),
        left: theme.spacing(2),
        width: 'auto',
        top: '60%',
    },
}));

const DragHandle = styled(Box)(({ theme }) => ({
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
}));

const QuizContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    overflowY: 'auto',
    height: 'auto',
    maxHeight: 'calc(80vh - 120px)', // Account for header and padding
    [theme.breakpoints.down('sm')]: {
        maxHeight: 'calc(100vh - 250px)',
        padding: theme.spacing(1),
    },
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: theme.palette.grey[100],
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[300],
        borderRadius: '4px',
        '&:hover': {
            background: theme.palette.grey[400],
        },
    },
}));

const QuestionSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    '&:last-child': {
        marginBottom: 0,
    },
}));

const getDifficultyColor = (difficulty: string) => {
    switch (difficulty.toUpperCase()) {
        case 'EASY': return 'success';
        case 'MEDIUM': return 'warning';
        case 'HARD': return 'error';
        default: return 'default';
    }
};

const SummaryQuiz: React.FC<SummaryQuizProps> = ({ lectureId, onTimeStampClick, transcriptionText }) => {
    const [quizzes, setQuizzes] = useState<Quiz[]>([]);
    const [quiz, setQuiz] = useState<Quiz | null>(null);
    const [answeredQuestions, setAnsweredQuestions] = useState<{ [key: number]: number }>({});
    const [showExplanations, setShowExplanations] = useState<{ [key: number]: boolean }>({});
    const [showTranscriptions, setShowTranscriptions] = useState<{ [key: number]: boolean }>({});
    const [isExpanded, setIsExpanded] = useState(false);
    const [error, setError] = useState("");
    const [isDetached, setIsDetached] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [difficultyFilters, setDifficultyFilters] = useState({
        easy: true,
        medium: true,
        hard: true,
    });
    const [score, setScore] = useState<number | null>(null);
    const [showScore, setShowScore] = useState(false);

    useEffect(() => {
        const loadQuiz = async () => {
            try {
                if (!lectureId) return;
                const loadedQuizzes = await fetchQuiz(lectureId);
                // reverse the array so the latest version is the first
                const reversedQuizzes = loadedQuizzes.reverse();
                if (reversedQuizzes.length > 0) {
                    setQuizzes(reversedQuizzes);
                    setQuiz(reversedQuizzes[0]);
                    logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "quiz_loaded", {
                        'lecture_id': lectureId,
                        'quiz_name': loadedQuizzes[0].title, 'version': loadedQuizzes[0].version
                    });
                }
            } catch (err) {
                setError('Failed to load quiz');
                console.error(err);
            }
        };
        loadQuiz();
    }, [lectureId]);

    const handleVersionChange = (id: string) => {
        const selectedQuiz = quizzes.find(q => q.id === id);
        if (selectedQuiz) {
            console.log(selectedQuiz);
            setQuiz(selectedQuiz);
            setAnsweredQuestions({});
            setShowExplanations({});
            setShowScore(false);
            setScore(null);
            logEvent(ANALYTICS_CATEGORIES.QUIZ, "version_changed");
        }
    };

    const calculateScore = () => {
        if (!quiz) return 0;
        let correct = 0;
        let total = 0;

        quiz.questions.forEach((question, index) => {
            if (index in answeredQuestions) {
                total++;
                if (answeredQuestions[index] === question.correct_answer) {
                    correct++;
                }
            }
        });

        return total > 0 ? (correct / total) * 100 : 0;
    };

    const isQuizComplete = () => {
        if (!quiz) return false;
        return quiz.questions.every((_, index) => index in answeredQuestions);
    };

    const handleAnswerSelect = (questionIndex: number, optionIndex: number) => {
        setAnsweredQuestions(prev => ({ ...prev, [questionIndex]: optionIndex }));
        setShowExplanations(prev => ({ ...prev, [questionIndex]: true }));

        // Calculate score when all questions are answered
        const newAnswers = { ...answeredQuestions, [questionIndex]: optionIndex };
        if (Object.keys(newAnswers).length === quiz?.questions.length) {
            const finalScore = calculateScore();
            setScore(finalScore);
            setShowScore(true);
            logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "quiz_completed", {
                'quiz_id': quiz?.id,
                'quiz_version': quiz?.version,
                'score': finalScore
            });
        }

        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "answer_selected", {
            'question_index': questionIndex,
            "option_index": optionIndex,
            "quiz_id": quiz?.id || "",
            "quiz_title": quiz?.title || "",
            "quiz_version": quiz?.version || "",
            "is_correct": optionIndex === quiz?.questions[questionIndex].correct_answer
        });
    };

    const toggleTranscription = (questionIndex: number) => {
        setShowTranscriptions(prev => ({
            ...prev,
            [questionIndex]: !prev[questionIndex]
        }));
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "toggle_transcription", { "question_index": questionIndex, "quiz_id": quiz?.id, "quiz_title": quiz?.title, "quiz_version": quiz?.version });
    };

    const handleTimeStampClick = (timestamp: string) => {
        if (onTimeStampClick && timestamp) {
            const [start] = timestamp.split(' - ');
            const [hours, minutes, seconds] = start.split(':').map(Number);
            const totalSeconds = hours * 3600 + minutes * 60 + seconds;
            onTimeStampClick(totalSeconds);

            logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "timestamp_clicked", {
                'timestamp': timestamp,
                'total_seconds': totalSeconds,
                'quiz_id': quiz?.id,
                'quiz_title': quiz?.title,
                'quiz_version': quiz?.version
            });
        }
    };

    const getRelevantTranscription = (sourceSegment: string[]): string[] | null => {
        if (!transcriptionText || !sourceSegment) return null;
        const [start, end] = sourceSegment[0].split(' - ');
        const startTime = timeToSeconds(start);
        const endTime = timeToSeconds(end);

        return transcriptionText.filter((segment) => {
            const segmentTime = timeToSeconds(segment.split(' - ')[0]);
            return segmentTime >= startTime && segmentTime <= endTime;
        });
    };

    const timeToSeconds = (time: string): number => {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    const handleDetach = () => {
        setIsDetached(true);
        setIsExpanded(true);
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "quiz_detached", {
            'quiz_id': quiz?.id,
            'quiz_title': quiz?.title,
            'quiz_version': quiz?.version,
            'window_width': window.innerWidth,
            'window_height': window.innerHeight
        });
    };

    const handleAttach = () => {
        setIsDetached(false);
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "quiz_attached", {
            'quiz_id': quiz?.id,
            'quiz_title': quiz?.title,
            'quiz_version': quiz?.version,
            'window_width': window.innerWidth,
            'window_height': window.innerHeight
        });
    };

    const handleDrag = (_e: any, data: { x: number; y: number }) => {
        setPosition({ x: data.x, y: data.y });

    };

    const handleDifficultyFilter = (difficulty: 'easy' | 'medium' | 'hard') => {
        setDifficultyFilters(prev => {
            const newFilters = {
                ...prev,
                [difficulty]: !prev[difficulty]
            };
            logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, "filter_difficulty", {
                'difficulty': difficulty,
                'new_state': !prev[difficulty],
                'quiz_id': quiz?.id,
                'quiz_title': quiz?.title
            });
            return newFilters;
        });
    };

    const handleExpandCollapse = () => {
        setIsExpanded(!isExpanded);
        logCustomEvent(ANALYTICS_CATEGORIES.QUIZ, isExpanded ? "collapse_quiz" : "start_quiz", {
            'quiz_id': quiz?.id,
            'quiz_title': quiz?.title,
            'quiz_version': quiz?.version
        });
    };

    const renderQuestion = (question: QuizQuestion, index: number) => {
        const isAnswered = index in answeredQuestions;
        const selectedAnswer = answeredQuestions[index];
        const showExplanation = showExplanations[index];
        const showTranscription = showTranscriptions[index];

        return (
            <QuestionSection key={index}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <Chip
                            label={question.difficulty}
                            color={getDifficultyColor(question.difficulty)}
                            size="small"
                            icon={<PsychologyIcon />}
                            sx={{ borderRadius: 1, p: 1, opacity: 0.9 }}
                        />

                    </Stack>
                    <Typography variant="body2" color="text.secondary" sx={{ opacity: 0.8 }}>
                        שאלה {index + 1} מתוך {filteredQuestions.length}
                    </Typography>
                </Box>

                {showTranscription && question.source_segment && (
                    <TranscriptionSection>
                        <Typography variant="body2" dir="rtl">
                            {getRelevantTranscription(question.source_segment)?.map((text: string, i: number) => (
                                <Box key={i} sx={{ mb: 1 }}>
                                    {text}
                                </Box>
                            ))}
                        </Typography>
                    </TranscriptionSection>
                )}

                <Typography
                    variant="h6"
                    sx={{
                        mb: 3,
                        fontWeight: 'bold',
                        lineHeight: 1.4,
                        color: 'text.primary'
                    }}
                    dir="rtl"
                >
                    {question.question}
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {question.options.map((option, optionIndex) => (
                        <OptionButton
                            key={optionIndex}
                            onClick={() => handleAnswerSelect(index, optionIndex)}
                            variant="outlined"
                            fullWidth
                            disabled={isAnswered}
                            sx={(theme) => ({
                                direction: 'rtl',
                                justifyContent: 'flex-start',
                                p: 2,
                                textAlign: 'right',
                                backgroundColor: showExplanation
                                    ? optionIndex === question.correct_answer
                                        ? 'rgba(76, 175, 80, 0.08)'
                                        : selectedAnswer === optionIndex
                                            ? 'rgba(244, 67, 54, 0.08)'
                                            : '#f0f0f0'
                                    : '#f0f0f0',
                                borderColor: showExplanation
                                    ? optionIndex === question.correct_answer
                                        ? 'success.main'
                                        : selectedAnswer === optionIndex
                                            ? 'error.main'
                                            : 'divider'
                                    : 'divider',
                                opacity: 0.95,
                                color: theme.palette.text.primary,
                                '&:hover': {
                                    backgroundColor: '#e8e8e8',
                                    opacity: 1
                                }
                            })}
                        >
                            {option}
                        </OptionButton>
                    ))}
                </Box>

                {showExplanation && (
                    <Fade in={showExplanation}>
                        <Paper
                            sx={{
                                mt: 3,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider'
                            }}
                        >
                            <Typography variant="subtitle1" fontWeight="bold" dir="rtl" color="primary.main">
                                הסבר:
                            </Typography>
                            <Typography dir="rtl" sx={{ mb: 2, color: 'text.primary' }}>
                                {question.explanation}
                            </Typography>

                            {question.source_segment && (
                                <>
                                    <Typography variant="body2" dir="rtl" sx={{ mb: 1 }}>
                                        השאלה מבוססת על קטע שמע מההרצאה שהופיע בזמן {question.source_segment.map((segment, index) => `${segment}` + (index < question.source_segment.length - 1 ? ", " : "")).join('')}
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleTimeStampClick(question.source_segment[0])}
                                        endIcon={<TimerIcon sx={{ mr: 1 }} />}
                                        sx={{ mt: 1 }}
                                    >
                                        נגן קטע שמע
                                    </Button>
                                </>
                            )}
                        </Paper>
                    </Fade>
                )}
            </QuestionSection>
        );
    };

    if (error) return null;
    if (!quiz) return null;

    const filteredQuestions = quiz.questions.filter(question =>
        (question.difficulty.toLowerCase() === 'easy' && difficultyFilters.easy) ||
        (question.difficulty.toLowerCase() === 'medium' && difficultyFilters.medium) ||
        (question.difficulty.toLowerCase() === 'hard' && difficultyFilters.hard)
    );

    const quizContent = (
        <QuizContainer>
            {filteredQuestions.map((question, index) => renderQuestion(question, index))}
        </QuizContainer>
    );

    if (isDetached) {
        return (
            <Portal>
                <Draggable position={position} onDrag={handleDrag} handle=".drag-handle">
                    <FloatingQuizCard>
                        <DragHandle className="drag-handle">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <DragIndicatorIcon color="action" />
                                <Typography variant="subtitle2">
                                    בחן את עצמך
                                </Typography>
                            </Stack>
                            <IconButton size="small" onClick={handleAttach}>
                                <CloseIcon />
                            </IconButton>
                        </DragHandle>
                        {quizContent}
                    </FloatingQuizCard>
                </Draggable>
            </Portal>
        );
    }

    return (
        <QuestionCard elevation={3} sx={{ mt: { xs: 2, sm: 3, md: 4 }, mb: 2 }}>
            <CardContent sx={{
                p: { xs: 1, sm: 2, md: 3 },
                '&:last-child': { pb: { xs: 2, sm: 3 } }
            }}>
                <Box sx={{ mb: 2 }}>
                    {/* First row */}
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 1.5
                    }}>
                        <Typography variant="h6" component="div" sx={{
                            fontWeight: 'bold',
                            color: 'primary.main',
                            fontSize: '1.25rem'
                        }}>
                            בחן את עצמך
                        </Typography>
                        {/* Version selector */}
                        {quizzes.length > 1 && (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="body2" sx={{ color: 'primary.main', px: 2 }}>גרסא  </Typography>
                                <ToggleButtonGroup
                                    value={quiz.id}
                                    exclusive
                                    onChange={(_, value) => value && handleVersionChange(value)}
                                    size="small"
                                    sx={{
                                        borderRadius: 4,
                                        border: "none",
                                        backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                        '& .MuiToggleButton-root': {
                                            border: 'none',
                                            minWidth: '32px',
                                            height: '32px',
                                            padding: '4px 8px',
                                            color: 'primary.main',
                                            fontSize: '0.875rem',
                                            transition: 'all 0.5s ease-in-out',
                                            '&:hover': {
                                                backgroundColor: 'rgba(25, 118, 210, 0.12)',
                                                transform: 'translateY(-1px)'
                                            },
                                            '&.Mui-selected': {
                                                borderRadius: 4,

                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: 'primary.dark'
                                                }
                                            }
                                        }
                                    }}
                                >
                                    {quizzes.map((q, index) => (
                                        <ToggleButton
                                            key={q.id}
                                            value={q.id}
                                            sx={{
                                                borderRadius: 4,
                                                '&.Mui-selected': {
                                                    animation: 'pulse 0.3s ease-in-out',
                                                    '@keyframes pulse': {
                                                        '0%': { transform: 'scale(1)' },
                                                        '50%': { transform: 'scale(0.95)' },
                                                        '100%': { transform: 'scale(1)' }
                                                    }
                                                }
                                            }}
                                        >
                                            {index + 1}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Stack>
                        )}

                        {/* Difficulty filters */}
                        <Stack direction="row" sx={{ mx: 2 }}>
                            <Chip
                                label="קל"
                                color="success"
                                variant={difficultyFilters.easy ? "filled" : "outlined"}
                                onClick={() => handleDifficultyFilter('easy')}
                                size="small"
                                sx={{
                                    px: 1,
                                    borderRadius: 0,
                                    cursor: 'pointer',
                                    opacity: difficultyFilters.easy ? 1 : 0.6,
                                    transition: 'all 0.2s ease'
                                }}
                            />
                            <Chip
                                label="בינוני"
                                color="warning"
                                variant={difficultyFilters.medium ? "filled" : "outlined"}
                                onClick={() => handleDifficultyFilter('medium')}
                                size="small"
                                sx={{
                                    px: 1,
                                    borderRadius: 0,
                                    cursor: 'pointer',
                                    opacity: difficultyFilters.medium ? 1 : 0.6,
                                    transition: 'all 0.2s ease'
                                }}
                            />
                            <Chip
                                label="קשה"
                                color="error"
                                variant={difficultyFilters.hard ? "filled" : "outlined"}
                                onClick={() => handleDifficultyFilter('hard')}
                                size="small"
                                sx={{
                                    px: 1,
                                    borderRadius: 0,
                                    cursor: 'pointer',
                                    opacity: difficultyFilters.hard ? 1 : 0.6,
                                    transition: 'all 0.2s ease'
                                }}
                            />
                        </Stack>

                        <IconButton
                            onClick={handleDetach}
                            size="small"
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                            }}
                            title="פתח בחלון נפרד"
                        >
                            <Typography sx={{ ml: 1 }} variant="caption">פתח במקביל לסיכום</Typography>
                            <OpenInNewIcon />
                        </IconButton>
                    </Box>

                    {/* Score display */}
                    {showScore && score !== null && (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                mb: 2,
                                bgcolor: score >= 70 ? 'rgba(76, 175, 80, 0.08)' : 'rgba(244, 67, 54, 0.08)',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: score >= 70 ? 'success.main' : 'error.main',
                                opacity: 0.9
                            }}
                        >
                            <Typography variant="h6" align="center" dir="rtl">
                                ציון סופי: {score.toFixed(1)}%
                            </Typography>
                            <Typography variant="body2" align="center" dir="rtl" sx={{ mt: 1 }}>
                                {score >= 70 ? 'כל הכבוד! עברת בהצלחה!' : 'המשך להתאמן כדי להשתפר'}
                            </Typography>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ mt: 2 }}
                                onClick={() => {
                                    setAnsweredQuestions({});
                                    setShowExplanations({});
                                    setShowScore(false);
                                    setScore(null);
                                }}
                            >
                                נסה שוב
                            </Button>
                        </Paper>
                    )}

                    {/* Second row - Start button */}
                    <Button
                        onClick={handleExpandCollapse}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            py: 1,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                        endIcon={isExpanded ? <ExpandLessIcon sx={{ mr: 1 }} /> : <ExpandMoreIcon sx={{ mr: 1 }} />}
                    >
                        {isExpanded ? "סגור" : "התחל"}
                    </Button>
                </Box>

                <Collapse in={isExpanded}>
                    {quizContent}
                </Collapse>
            </CardContent>
        </QuestionCard>
    );
};

export default SummaryQuiz; 
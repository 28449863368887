import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  Skeleton,
  ImageList,
  ImageListItem,
  Tooltip,
  IconButton,
  Collapse,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Paper,
  Modal,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeRaw from 'rehype-raw';
import SmartToyTwoToneIcon from "@mui/icons-material/SmartToyTwoTone";
import ConversationHistory from "./ConversationHistory";
import { QueryInput } from "./QueryInput";
import { RatingComponent } from "../RatingComponent";
import { componentStyles } from "../styles";
import { ChatMessage, ImageObject, ChatSession } from "../Constants/interface";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ImageIcon from '@mui/icons-material/Image';
import LectureReference from './LectureReference';

interface ChatBoxProps {
  loading: boolean;
  active: boolean;
  partialResponse: string;
  chatSession: ChatSession;
  cardRTL: boolean;
  alignment: string;
  query: string;
  sessionId: string;
  handleFeedback: (index: number, isPositive: boolean) => void;
  handleAligmentChange: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => void;
  handleShare: () => void;
  handleClickOpen: () => void;
  setTimer: (value: React.SetStateAction<number>) => void;
  onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onQuerySubmit: () => void;
  stage?: 'retrieval' | 'filtering' | 'response';
  filteringStream?: {
    chunks: any[];
    images: ImageObject[];
  } | null;
}

export const ImageModal: React.FC<{
  image: ImageObject | string | null;
  open: boolean;
  onClose: () => void;
}> = ({ image, open, onClose }) => {
  if (!image) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        sx: {
          bgcolor: 'background.paper',
          borderRadius: 2,
          maxHeight: '90vh',
        }
      }}
    >
      <DialogContent sx={{ p: 0, position: 'relative' }}>
        <img
          src={typeof image === 'string' ? image : image.url}
          alt={typeof image === 'string' ? '' : image.description}
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '70vh',
            objectFit: 'contain'
          }}
        />
        <Paper
          elevation={3}
          sx={{
            p: 2,
            m: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            borderRadius: 1,
            direction: 'rtl'
          }}
        >
          <Typography variant="body1">{typeof image === 'string' ? '' : image.description}</Typography>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export const ImageGallery: React.FC<{ images: ImageObject[] }> = ({ images }) => {
  const [selectedImage, setSelectedImage] = React.useState<ImageObject | null>(null);

  // Filter out duplicate images based on URL
  const uniqueImages = images.filter((image, index, self) =>
    index === self.findIndex((img) => img.url === image.url)
  );

  if (!uniqueImages.length) return null;

  return (
    <>
      <Box
        sx={{
          width: '90%',
          mb: 3,
          p: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 2, color: 'white' }}>
          תמונות רלוונטיות ({uniqueImages.length})
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {uniqueImages.map((img, index) => (
            <Grid item key={index} xs={4} sm={3} md={2} lg={2}>
              <Paper
                elevation={3}
                sx={{
                  position: 'relative',
                  paddingTop: '100%', // 1:1 Aspect ratio
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                onClick={() => setSelectedImage(img)}
              >
                <img
                  src={img.url}
                  alt={img.description}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: 'inherit',
                  }}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <ImageModal
        image={selectedImage}
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
      />
    </>
  );
};

export const MarkdownImage = (props: React.ComponentPropsWithoutRef<'img'> & { setEnlargedImage: (src: string) => void }) => (
  <img
    {...props}
    onClick={() => props.setEnlargedImage(props.src || '')}
    src={props.src?.replace(/\\\\/g, '/')}
    style={{
      maxWidth: '50vw',
      height: 'auto',
      display: 'block',
      margin: '1rem auto',
    }}
  />
);


export const ChatBox: React.FC<ChatBoxProps> = ({
  loading,
  active,
  partialResponse,
  chatSession,
  cardRTL,
  alignment,
  query,
  sessionId,
  handleFeedback,
  handleAligmentChange,
  handleShare,
  handleClickOpen,
  setTimer,
  onQueryChange,
  onQuerySubmit,
  filteringStream,
  stage,
}) => {
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const styles = componentStyles;

  const getStageMessage = () => {
    switch (stage) {
      case 'retrieval':
        return 'מחפש מידע...';
      case 'filtering':
        return 'מחלץ סיכומים רלוונטיים...';
      case 'response':
        return 'מייצר תשובה...';
      default:
        return 'מעבד בקשה...';
    }
  };

  return (
    <Box sx={styles.conversationBox}>
      <ConversationHistory
        onFeedback={handleFeedback}
        cardRTL={cardRTL}
        conversationHistory={chatSession.messages}
      />

      {loading && stage !== 'response' ? (
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={styles.skeletonResponseCard}
        />
      ) : (
        active && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card sx={styles.partialResponseCard}>
              <Box position="relative" display="flex" flexDirection="column">
                <Box position="absolute" top={0} left={0} p={0}>
                  <SmartToyTwoToneIcon />
                </Box>
                <CardContent>
                  <ReactMarkdown
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeKatex, rehypeRaw]}
                    components={{
                      img: (props) => <MarkdownImage {...props} setEnlargedImage={setEnlargedImage} />,
                      // Add custom heading components to reduce level
                      h1: ({ children }) => <h2>{children}</h2>,
                      h2: ({ children }) => <h3>{children}</h3>,
                      h3: ({ children }) => <h4>{children}</h4>,
                    }}
                  >
                    {partialResponse}
                  </ReactMarkdown>
                </CardContent>
              </Box>
            </Card>

            <CircularProgress color="warning" />
            <Box sx={styles.thinkingText}>{getStageMessage()}</Box>
          </Box>
        )
      )}

      {loading && (
        <Box sx={styles.loadingBox}>
          <Box sx={styles.linearProgressBox}>
            {(
              <Box sx={{ width: '100%', position: 'relative' }}>

                <Typography
                  variant="caption"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: -20,
                    color: 'rgba(255, 255, 255, 0.7)',
                  }}
                >
                  {getStageMessage()}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {filteringStream && filteringStream.chunks.length > 0 && (
        <LectureReference images={filteringStream.images} chunks={filteringStream.chunks} />
      )}

      <QueryInput
        alignment={alignment}
        handleAligmentChange={handleAligmentChange}
        handleShare={handleShare}
        handleClickOpen={handleClickOpen}
        setTimer={setTimer}
        query={query}
        onQueryChange={onQueryChange}
        onQuerySubmit={onQuerySubmit}
        loading={loading}
        sessionId={sessionId}
      />
      {enlargedImage && (

        <ImageModal
          image={enlargedImage}
          open={!!enlargedImage}
          onClose={() => setEnlargedImage(null)}
        />
      )}
    </Box>
  );
};


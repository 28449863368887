import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    Chip,
    Paper,
    Container
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { Quiz, QuizResponse } from './Constants/interface';
import { API_BASE_URL, fetchQuiz } from './Transcription/Services/firebaseService';

const QuickQuiz = () => {
    const { lectureId } = useParams<{ lectureId: string }>();
    const [quiz, setQuiz] = useState<Quiz | null>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState<number[]>([]);
    const [showExplanation, setShowExplanation] = useState(false);
    const [error, setError] = useState("");
    const [isQuizCompleted, setIsQuizCompleted] = useState(false);

    useEffect(() => {
        const loadQuiz = async () => {
            try {

                if (!lectureId) return;
                const quizzes = await fetchQuiz(lectureId);
                if (quizzes.length > 0) {
                    setQuiz(quizzes[0]); // Take the first quiz for now
                }
            } catch (err) {
                setError('Failed to load quiz data');
                console.error(err);
            }
        };
        loadQuiz();
    }, [lectureId]);

    const handleAnswerSelect = async (optionIndex: number) => {
        setAnswers(prev => [...prev, optionIndex]);
        setShowExplanation(true);

        // If this was the last question, submit the quiz
        if (currentQuestionIndex === (quiz?.questions.length || 0) - 1) {
            const quizResponse: Omit<QuizResponse, 'id'> = {
                quizId: quiz?.id || '',
                userId: 'current-user-id', // You'll need to implement user authentication
                answers: [...answers, optionIndex],
                score: quiz ? calculateScore([...answers, optionIndex], quiz) : 0,
                time_taken: 0, // Implement timer if needed
                completed_at: new Date().toISOString(),
            };

            try {
                if (!quiz) return;
                // Submit quiz response to backend
                const response = await fetch(`${API_BASE_URL}/lectures/${lectureId}/quizzes/${quiz.id}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(quizResponse)
                });

                if (!response.ok) throw new Error('Failed to submit quiz');
                setIsQuizCompleted(true);
            } catch (err) {
                setError('Failed to submit quiz');
                console.error(err);
            }
        }
    };

    const calculateScore = (userAnswers: number[], quiz: Quiz): number => {
        let correct = 0;
        userAnswers.forEach((answer, index) => {
            if (answer === quiz.questions[index].correct_answer) correct++;
        });
        return (correct / quiz.questions.length) * 100;
    };

    const handleNext = () => {
        if (currentQuestionIndex < (quiz?.questions.length || 0) - 1) {
            setCurrentQuestionIndex(prev => prev + 1);
            setShowExplanation(false);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prev => prev - 1);
            setShowExplanation(true);
        }
    };

    if (!quiz) return <Typography>Loading quiz...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    const currentQuestion = quiz.questions[currentQuestionIndex];

    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Card elevation={3} sx={{ /* existing styles */ }}>
                <CardContent sx={{ p: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Chip
                            label={currentQuestion.difficulty}
                            color={getDifficultyColor(currentQuestion.difficulty)}
                            size="small"
                        />
                        <Typography>
                            שאלה {currentQuestionIndex + 1} מתוך {quiz.questions.length}
                        </Typography>
                    </Box>

                    <Typography variant="h5" component="div" sx={{ mb: 4, fontWeight: 'bold' }} dir="rtl">
                        {currentQuestion.question}
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {currentQuestion.options.map((option, index) => (
                            <Button
                                key={index}
                                onClick={() => handleAnswerSelect(index)}
                                variant="outlined"
                                fullWidth
                                disabled={showExplanation}
                                sx={{
                                    direction: 'rtl',
                                    justifyContent: 'flex-start',
                                    p: 2,
                                    textAlign: 'right',
                                    backgroundColor: showExplanation
                                        ? index === currentQuestion.correct_answer
                                            ? 'success.light'
                                            : answers[currentQuestionIndex] === index
                                                ? 'error.light'
                                                : 'inherit'
                                        : 'inherit'
                                }}
                            >
                                {option}
                            </Button>
                        ))}
                    </Box>

                    {showExplanation && (
                        <Paper sx={{ mt: 3, p: 2, bgcolor: 'info.light' }}>
                            <Typography variant="subtitle1" fontWeight="bold" dir="rtl">
                                הסבר:
                            </Typography>
                            <Typography dir="rtl">
                                {currentQuestion.explanation}
                            </Typography>
                        </Paper>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                        <Button
                            startIcon={<NavigateBeforeIcon />}
                            onClick={handlePrevious}
                            disabled={currentQuestionIndex === 0}
                        >
                            Previous
                        </Button>
                        <Button
                            endIcon={<NavigateNextIcon />}
                            onClick={handleNext}
                            disabled={currentQuestionIndex === quiz.questions.length - 1 || !showExplanation}
                        >
                            Next
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    );
};

const getDifficultyColor = (difficulty: string) => {
    switch (difficulty) {
        case 'EASY': return 'success';
        case 'MEDIUM': return 'warning';
        case 'HARD': return 'error';
        default: return 'default';
    }
};

export default QuickQuiz;

import React, { useState, useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Chip,
    CircularProgress,
    Collapse,
    IconButton,
    Button,
    Portal,
    Card,
    Stack,
} from '@mui/material';
import { Lecture, Chunk, ImageObject } from '../Constants/interface';
import { fetchSummary, SummaryResponse } from '../Transcription/Services/firebaseService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Markdown from '../Transcription/Markdown';
import { createStyles } from '../Transcription/styles';
import { ImageGallery } from './ChatBox';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MinimizeIcon from '@mui/icons-material/Minimize';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Draggable from 'react-draggable';
import { styled } from '@mui/material/styles';
import { ANALYTICS_CATEGORIES, logCustomEvent, logEvent } from '../utils/Analytics';

interface CombinedLectureData extends SummaryResponse {
    name: string;
    lecture_id?: string;
    subject?: string;
    category?: "MEDICAL" | "NON_MEDICAL" | "UNKNOWN";
    subFolder?: string;
    location?: string;
}

interface LectureReferenceProps {
    chunks?: Chunk[];
    images?: ImageObject[];
    // For search component usage
    lecture?: CombinedLectureData;
    open?: boolean;
    onClose?: () => void;
}

interface LectureDialogState {
    lecture: CombinedLectureData;
    position: { x: number; y: number };
    isMinimized: boolean;
    isDetached: boolean;
}

const extractLastHeader = (location: string): string | null => {
    console.log('location', location);
    const paths = location.split('/');
    // Get the last header (most specific one)
    const lastHeader = paths[paths.length - 1];
    console.log('lastHeader', lastHeader);
    if (!lastHeader) return null;

    // Extract the header text without the timestamp
    const match = lastHeader.match(/(.*?)\s*\(\d{2}:\d{2}:\d{2}/);
    return match ? match[1].trim() : null;
};

const findHeaderElement = (headerText: string): HTMLElement | null => {
    const headers = document.querySelectorAll('h1, h2, h3, h4, h5, h6');

    for (const header of Array.from(headers)) {
        if (header.textContent?.includes(headerText)) {
            console.log('found header', headerText);
            return header as HTMLElement;
        }
    }
    console.log('did not find header', headerText);
    return null;
};

const FloatingLectureCard = styled(Card)(({ theme }) => ({
    position: 'fixed',
    top: '20%',
    right: '20px',
    width: '500px',
    maxWidth: '90vw',
    maxHeight: '80vh',
    zIndex: theme.zIndex.modal,
    background: theme.palette.mode === 'dark'
        ? 'linear-gradient(145deg, #2d2d2d 0%, #1a1a1a 100%)'
        : 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
    boxShadow: '0 8px 32px rgba(0,0,0,0.15)',
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
}));

const DragHandle = styled(Box)(({ theme }) => ({
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.mode === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
    userSelect: 'none',
}));

const MinimizedContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.mode === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
}));

const createDialogContent = (
    lecture: CombinedLectureData,
    fontSize: number,
    showImages: boolean,
    isDetached: boolean,
    isMinimized: boolean,
    handleImageClick: (src: string) => void
) => (
    <>
        <Box sx={{ mb: 2, direction: 'rtl', textAlign: 'right' }}>
            {lecture.subject && (
                <Chip
                    label={lecture.subject}
                    color="primary"
                    size="small"
                    sx={{ ml: 1, mb: 1 }}
                />
            )}
            {lecture.category && (
                <Chip
                    label={lecture.category}
                    color="secondary"
                    size="small"
                    sx={{ ml: 1, mb: 1 }}
                />
            )}
            {lecture.subFolder && (
                <Chip
                    label={lecture.subFolder}
                    color="secondary"
                    size="small"
                    sx={{ mb: 1 }}
                />
            )}
        </Box>

        <Collapse in={!isMinimized}>
            <Box sx={{
                maxHeight: isDetached ? '60vh' : '50vh',
                overflow: 'auto',
                direction: 'rtl',
                textAlign: 'right',
                transition: 'all 0.3s ease',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'rgba(0,0,0,0.05)',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(0,0,0,0.2)',
                    borderRadius: '4px',
                    '&:hover': {
                        background: 'rgba(0,0,0,0.3)',
                    },
                },
            }}>
                <Markdown
                    fontSize={fontSize}
                    isLongForm={true}
                    handleImageClick={handleImageClick}
                    showImages={showImages}
                    isTocOpen={false}
                    showTimeStamp={false}
                >
                    {lecture.fullSummary || 'Loading summary...'}
                </Markdown>
            </Box>
        </Collapse>
    </>
);

const LectureReference: React.FC<LectureReferenceProps> = ({ chunks = [], images = [], lecture, open, onClose }) => {
    const [expanded, setExpanded] = useState(true);
    const [lectureCache, setLectureCache] = useState<Record<string, CombinedLectureData>>({});
    const [loading, setLoading] = useState<Record<string, boolean>>({});
    const [detachedLectures, setDetachedLectures] = useState<Record<string, LectureDialogState>>({});

    // Handle the search component use case
    useEffect(() => {
        if (lecture && open) {
            const lectureId = lecture.lecture_id || '';
            if (!detachedLectures[lectureId]) {
                setDetachedLectures(prev => ({
                    ...prev,
                    [lectureId]: {
                        lecture,
                        position: {
                            x: Object.keys(prev).length * 30,
                            y: Object.keys(prev).length * 30
                        },
                        isMinimized: false,
                        isDetached: true
                    }
                }));
            }
        }
    }, [lecture, open]);

    // // Handle scrolling to relevant section when window opens
    // useEffect(() => {
    //     Object.values(detachedLectures).forEach(state => {
    //         console.log('state', state);
    //         const headerText = extractLastHeader(state.lecture.location || '');
    //         handleNavigate(headerText || '');
    //         logEvent(ANALYTICS_CATEGORIES.CONTENT, "open_lecture_reference", state.lecture.lecture_id);
    //     });
    // }, [detachedLectures]);

    const handleNavigate = (headerText: string) => {
        // Add a longer initial delay to ensure content is rendered
        setTimeout(() => {
            const element = findHeaderElement(headerText);
            if (element) {
                console.log('element', element);
                const expandParents = () => {
                    let currentElement = element;
                    const headersToExpand: string[] = [];
                    while (currentElement && currentElement.parentElement) {
                        const parentHeader = currentElement.closest('[data-header-level]');
                        if (parentHeader && parentHeader.classList.contains('collapsed')) {
                            headersToExpand.push(parentHeader.id);
                        }
                        currentElement = currentElement.parentElement;
                    }

                    // Expand headers sequentially with small delays
                    headersToExpand.reverse().forEach((headerId, index) => {
                        setTimeout(() => {
                            const headerElement = document.getElementById(headerId);
                            if (headerElement) {
                                const headerButton = headerElement.querySelector('button');
                                if (headerButton) {
                                    headerButton.click();
                                }
                            }
                        }, index * 100); // Add small delay between each expansion
                    });

                    // Scroll after all expansions are complete
                    setTimeout(() => {
                        element.classList.add('highlight-header');
                        element.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });

                        // Create highlight overlay
                        const overlay = document.createElement('div');
                        overlay.style.position = 'absolute';
                        overlay.style.top = '0';
                        overlay.style.left = '0';
                        overlay.style.right = '0';
                        overlay.style.bottom = '0';
                        overlay.style.backgroundColor = 'rgba(255, 255, 0, 0.2)';
                        overlay.style.pointerEvents = 'none';
                        overlay.style.animation = 'fadeOut 2s forwards';

                        const elementPosition = element.getBoundingClientRect();
                        overlay.style.width = `${elementPosition.width}px`;
                        overlay.style.height = `${elementPosition.height}px`;

                        element.style.position = 'relative';
                        element.appendChild(overlay);

                        // Cleanup
                        setTimeout(() => {
                            element.classList.remove('highlight-header');
                            if (element.contains(overlay)) {
                                element.removeChild(overlay);
                            }
                        }, 2000);
                    }, headersToExpand.length * 100 + 100); // Wait for all expansions to complete
                };

                expandParents();
            }
        }, 500); // Increased initial delay
    };
    const uniqueLectures = chunks.reduce((acc, chunk) => {
        if (!acc[chunk.lecture_id]) {
            acc[chunk.lecture_id] = chunk;
        }
        return acc;
    }, {} as Record<string, Chunk>);

    const handleLectureClick = async (lectureId: string) => {
        // If the lecture is already detached, just ensure it's not minimized
        if (detachedLectures[lectureId]) {
            setDetachedLectures(prev => ({
                ...prev,
                [lectureId]: {
                    ...prev[lectureId],
                    isMinimized: false
                }
            }));
            logEvent(ANALYTICS_CATEGORIES.CONTENT, "restore_minimized_lecture", lectureId);
            return;
        }

        // If the lecture is in cache, create a detached window immediately
        if (lectureCache[lectureId]) {
            const lecture = lectureCache[lectureId];
            setDetachedLectures(prev => ({
                ...prev,
                [lectureId]: {
                    lecture,
                    position: {
                        x: Object.keys(prev).length * 30,
                        y: Object.keys(prev).length * 30
                    },
                    isMinimized: false,
                    isDetached: true
                }
            }));
            logEvent(ANALYTICS_CATEGORIES.CONTENT, "open_cached_lecture", lectureId);
            return;
        }

        // Otherwise, fetch the lecture data and create a detached window
        try {
            logCustomEvent(ANALYTICS_CATEGORIES.CONTENT, "fetch_lecture_start", { 'lecture_id': lectureId, 'lecture_name': lecture?.name });
            setLoading(prev => ({ ...prev, [lectureId]: true }));
            const summaryData = await fetchSummary(lectureId);
            const dataChunk = chunks.find(chunk => chunk.lecture_id === lectureId);

            const combinedData: CombinedLectureData = {
                ...summaryData,
                lecture_id: lectureId,
                name: dataChunk?.lecture_name || '',
                subject: dataChunk?.subject,
                subFolder: dataChunk?.subfolder,
                location: dataChunk?.location
            };

            setLectureCache(prev => ({ ...prev, [lectureId]: combinedData }));

            // Create detached window with offset based on number of existing windows
            setDetachedLectures(prev => ({
                ...prev,
                [lectureId]: {
                    lecture: combinedData,
                    position: {
                        x: Object.keys(prev).length * 30,
                        y: Object.keys(prev).length * 30
                    },
                    isMinimized: false,
                    isDetached: true
                }
            }));
            logEvent(ANALYTICS_CATEGORIES.CONTENT, "fetch_lecture_success", lectureId);
            const headerText = extractLastHeader(combinedData.location || '');
            handleNavigate(headerText || '');
            logCustomEvent(ANALYTICS_CATEGORIES.CONTENT, "open_lecture_reference", {
                'lecture_id': combinedData.lecture_id,
                'lecture_name': combinedData.name,
                'subject': combinedData.subject,
            });
        } catch (error) {
            console.error('Error fetching lecture:', error);
            logEvent(ANALYTICS_CATEGORIES.CONTENT, "fetch_lecture_error", `${lectureId}: ${error}`);
        } finally {
            setLoading(prev => ({ ...prev, [lectureId]: false }));
        }
    };

    const handleCloseLecture = (lectureId: string) => {
        logEvent(ANALYTICS_CATEGORIES.CONTENT, "close_lecture", lectureId);
        setDetachedLectures(prev => {
            const newState = { ...prev };
            delete newState[lectureId];
            return newState;
        });
        if (onClose && lecture?.lecture_id === lectureId) {
            onClose();
        }
    };

    const handleMinimizeLecture = (lectureId: string) => {
        setDetachedLectures(prev => {
            const isCurrentlyMinimized = prev[lectureId].isMinimized;
            logEvent(
                ANALYTICS_CATEGORIES.CONTENT,
                isCurrentlyMinimized ? "maximize_lecture" : "minimize_lecture",
                lectureId
            );
            return {
                ...prev,
                [lectureId]: {
                    ...prev[lectureId],
                    isMinimized: !isCurrentlyMinimized
                }
            };
        });
    };

    const handleUpdatePosition = (lectureId: string, position: { x: number; y: number }) => {
        logEvent(ANALYTICS_CATEGORIES.CONTENT, "move_lecture", `${lectureId}: x=${position.x}, y=${position.y}`);
        setDetachedLectures(prev => ({
            ...prev,
            [lectureId]: {
                ...prev[lectureId],
                position
            }
        }));
    };

    // Handle expanding/collapsing the list
    const handleExpandToggle = () => {
        logEvent(ANALYTICS_CATEGORIES.CONTENT, expanded ? "collapse_lecture_list" : "expand_lecture_list");
        setExpanded(!expanded);
    };

    // If used in search mode and no lecture is provided, return null
    if (!chunks.length && !lecture) return null;
    // If used in chat mode and no lectures are available, return null
    if (chunks.length && Object.keys(uniqueLectures).length === 0) return null;

    return (
        <>
            {chunks.length > 0 && (
                <Box sx={{
                    width: '100%',
                    mb: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    borderRadius: 2,
                    border: '1px solid rgba(255, 255, 255, 0.12)',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 1.5,
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                fontSize: '0.9rem'
                            }}
                        >
                            <MenuBookIcon sx={{ fontSize: '1.1rem' }} />
                            מקורות רלוונטיים ({Object.keys(uniqueLectures).length})
                        </Typography>
                        <IconButton
                            size="small"
                            onClick={handleExpandToggle}
                            sx={{ color: 'white' }}
                        >
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>

                    <Collapse in={expanded}>
                        <Box sx={{
                            p: 1,
                            direction: 'rtl',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <ImageGallery images={images} />
                        </Box>
                        <List sx={{
                            p: 1,
                            direction: 'rtl',
                            maxHeight: '200px',
                            overflowY: 'auto'
                        }}>
                            {Object.values(uniqueLectures).map((chunk) => (
                                <ListItem
                                    key={chunk.lecture_id}
                                    disablePadding
                                    sx={{ mb: 1, direction: 'rtl', textAlign: 'right' }}
                                >
                                    <ListItemButton
                                        onClick={() => handleLectureClick(chunk.lecture_id)}
                                        sx={{
                                            bgcolor: 'rgba(255, 255, 255, 0.05)',
                                            borderRadius: 1,
                                            '&:hover': {
                                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                                            },
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography sx={{ color: 'white', direction: 'rtl', textAlign: 'right' }}>
                                                    {chunk.lecture_name}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', direction: 'rtl', textAlign: 'right' }}>
                                                    {chunk.subject} • {chunk.subfolder}
                                                </Typography>
                                            }
                                        />
                                        {loading[chunk.lecture_id] && (
                                            <CircularProgress size={20} sx={{ ml: 2 }} />
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </Box>
            )}

            {Object.entries(detachedLectures).map(([lectureId, state]) => {
                const dialogContent = createDialogContent(
                    state.lecture,
                    1, // Default font size for detached dialogs
                    true, // Always show images in detached dialogs
                    true, // Is detached
                    state.isMinimized,
                    (src: string) => window.open(src, '_blank')
                );

                return (
                    <Portal key={lectureId}>
                        <Draggable
                            position={state.position}
                            onDrag={(_e, data) => handleUpdatePosition(lectureId, { x: data.x, y: data.y })}
                            handle=".drag-handle"
                        >
                            <FloatingLectureCard>
                                <DragHandle className="drag-handle">
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <DragIndicatorIcon color="action" />
                                        <Typography variant="subtitle2">
                                            {state.lecture.name}
                                        </Typography>
                                    </Stack>
                                    <Box>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleMinimizeLecture(lectureId)}
                                        >
                                            {state.isMinimized ? <OpenInFullIcon /> : <MinimizeIcon />}
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleCloseLecture(lectureId)}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </DragHandle>
                                <DialogContent>
                                    {state.isMinimized ? (
                                        <MinimizedContent>
                                            <Typography variant="body2" noWrap>
                                                {state.lecture.name}
                                            </Typography>
                                        </MinimizedContent>
                                    ) : (
                                        dialogContent
                                    )}
                                </DialogContent>
                            </FloatingLectureCard>
                        </Draggable>
                    </Portal>
                );
            })}
        </>
    );
};

// Update the styles to include collapsed state
const styles = `
    @keyframes pulse {
        0% { opacity: 1; }
        50% { opacity: 0.5; }
        100% { opacity: 1; }
    }

    @keyframes fadeOut {
        from { opacity: 0.5; }
        to { opacity: 0; }
    }

    @keyframes highlightFade {
        0% { background-color: rgba(255, 255, 0, 0.3); }
        100% { background-color: transparent; }
    }

    .highlight-header {
        position: relative;
        z-index: 1;
        animation: highlightFade 2s ease-out forwards;
    }

    .collapsed {
        display: none;
    }
`;

export default LectureReference;
// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  CircularProgress,
  Button,
  IconButton,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import { Log, RowLog, VisitLog } from "./Constants/interface";
import ConversationHistory from "./chat/ConversationHistory";
import { AggregatedData, aggregateDataByIP } from "./aggreateSolutionLogs";

export const logStyles = {
  tableContainer: {
    margin: "auto",
    marginTop: 4,
    boxShadow: 3,
    maxWidth: { xs: "98vw" }, // Added for responsiveness
    overflowX: "auto", // Added for responsiveness
  },
  headerCell: {
    backgroundColor: "#007bff",
    color: "white",
    fontSize: 16,
  },
  row: {
    maxWidth: { xs: "98vw" },
    "&:hover": {
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },
    "& td": {
      wordBreak: "break-word",
    },
  },
  detailText: {
    maxWidth: { xs: "98vw" },
    textDirection: "rtl",
    fontFamily: "monospace",
    padding: "10px",
    backgroundColor: "#f7f7f7",
    borderRadius: 2,
    wordBreak: "break-word",
    whiteSpace:
      "pre-wrap" /* Preserve newlines and formatting within detailText */,
  },
  header: {
    textAlign: "center",
    margin: 2,
  },
};

const LogsPage = () => {
  const [visitLogs, setVisitLogs] = useState<VisitLog[]>([]);
  const [solutionLogs, setSolutionLogs] = useState<AggregatedData[]>([]);

  const [logs, setLogs] = useState<Log[]>([]);
  const [selectedTable, setSelectedTable] = useState("instantLogs"); // Default table to show

  const [instantlogs, setInstantLogs] = useState<Log[]>([]);

  const [expandedLog, setExpandedLog] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const baseUrl = `https://database-handling.azurewebsites.net/api/get_posts?container=Chats&query=SELECT%20TOP%20400%20*%20FROM%20c%20ORDER%20BY%20c.timestamp%20DESC`;
  const [sortOrder, setSortOrder] = useState("desc"); // 'asc' for ascending, 'desc' for descending

  function isLog(log: VisitLog | Log): log is VisitLog {
    return (log as VisitLog).data !== undefined;
  }
  function safeParse(json: string) {
    try {
      return JSON.parse(json);
    } catch (error) {
      console.error("Failed to parse JSON:", json);
      return { ip: "not found", city: "not found" }; // or return a default value
    }
  }
  const fetchVisitLogs = () => {
    setLoading(true);
    fetch(
      `https://database-handling.azurewebsites.net/api/get_posts?container=userVisits&query=SELECT%20TOP%20100%20*%20FROM%20c%20ORDER%20BY%20c.timestamp%20DESC`
    )
      .then((response) => response.json())
      .then((data: VisitLog[]) => {
        const logs = data;
        console.log(`called fetch logs, the logs are \n\n\n`, logs);

        setVisitLogs(
          logs.filter(
            (log) =>
              (log.data ? log.data.ip != "77.127.13.17" : false) &&
              !log.data?.PressedInTestSolutions
          )
        );

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching logs:", error);
        setLoading(false);
      });
  };
  const handleFeedback = (index: number, isPositive: boolean) => {
    // Here, you would implement the logic to send this feedback to your database
    console.log(
      `Feedback for message ${index}: ${isPositive ? "Positive" : "Negative"}`
    );
  };

  const fetchRowLogs = () => {
    setLoading(true);
    fetch(
      `https://database-handling.azurewebsites.net/api/get_posts?container=userVisits`
    )
      .then((response) => response.json())
      .then((data: RowLog[]) => {
        const logs = data.reverse();

        setSolutionLogs(
          aggregateDataByIP(
            logs.filter((log) =>
              log.data?.PressedInTestSolutions
                ? log.data.PressedInTestSolutions
                : false
            )
          )
        );

        console.log(
          aggregateDataByIP(
            logs.filter((log) =>
              log.data?.PressedInTestSolutions
                ? log.data.PressedInTestSolutions
                : false
            )
          )
        );

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching logs:", error);
        setLoading(false);
      });
  };

  const fetchUsageLogs = () => {
    setLoading(true);
    fetch(baseUrl)
      .then((response) => response.json())
      .then((data: Log[]) => {
        const logs = data;
        setLogs(logs.filter((log) => log.chatHistory && !log.isInstant));
        setInstantLogs(logs.filter((log) => log.chatHistory && log.isInstant));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching logs:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchUsageLogs();
    fetchVisitLogs();
    fetchRowLogs();
  }, []);
  const handleSortByChatHistoryLength = () => {
    const sortedLogs = [...logs].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.chatHistory.length - b.chatHistory.length;
      } else {
        return b.chatHistory.length - a.chatHistory.length;
      }
    });
    setLogs(sortedLogs);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Toggle the sort order for the next click
  };

  const navigate = useNavigate();

  const handleRowClick = (logId: string) => {
    setExpandedLog(expandedLog === logId ? null : logId);
  };
  const cellStyles = {
    overflow: "hidden", // Prevent overflow
    textOverflow: "ellipsis", // Add ellipsis for overflowed text
  };

  const queryCellStyles = {
    ...cellStyles,
    minWidth: "150px",
    fontSize: "10px",
    m: "1px",
    p: "0px",
    pl: "10px",
    direction: "rtl", // Right-to-left text
  };
  const formatDefinitionsForMarkdown = (definitions: {
    [key: string]: [string, string];
  }): string => {
    return Object.entries(definitions)
      .map(
        ([term, [engDef, localDef]]) =>
          `**${term}**: ${engDef}\n\n*${localDef}*`
      )
      .join("\n\n");
  };
  const formatTime = (time: string) => {
    const timestamp = time.replace("item-", "");

    const date = new Date(timestamp);

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(2); // Get last two digits of the year

    // Construct the format: "hh:mm:ss:dd:yy"
    return `${hours}:${minutes}  ${day}/${month}/${year}`;
    // Step 4: Format the updated Date object back into the ISO 8601 format
  };
  const sortedLogs = [...visitLogs, ...instantlogs].sort((a, b) => {
    // Extracting the timestamp or id as date for comparison
    const dateA = isLog(a)
      ? new Date(a.timestamp)
      : new Date(a.id.replace("item-", ""));
    const dateB = isLog(b)
      ? new Date(b.timestamp)
      : new Date(b.id.replace("item-", ""));
    // Comparing the dates for descending order
    return dateB.getTime() - dateA.getTime();
  });

  // Function to render the selected table
  const renderSelectedTable = () => {
    switch (selectedTable) {
      case "instantLogs":
        return renderInstantLogsTable();
      case "chats":
        return renderChatsTable();
      case "visits":
        return renderVisitsTable();
      case "testSolutions":
        return renderTestSolutionsTable();
      default:
        return null; // Or any default UI
    }
  };

  // Individual functions to render each table
  const renderInstantLogsTable = () => {
    return (
      <>
        <Typography variant="h5">Instant Logs</Typography>
        <TableContainer component={Paper} sx={logStyles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={logStyles.headerCell}>Query</TableCell>
                <TableCell sx={logStyles.headerCell}>Ip</TableCell>
                <TableCell sx={logStyles.headerCell}>City</TableCell>
                <TableCell sx={logStyles.headerCell}>Timestamp</TableCell>
                <TableCell sx={logStyles.headerCell}>Mobile</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {instantlogs.map((log) => (
                <React.Fragment key={log.id}>
                  <TableRow
                    sx={{
                      ...logStyles.row,
                      backgroundColor: log.shared
                        ? "rgba(235, 199, 199,0.2)"
                        : log.WhatsappShared
                          ? "rgba(48, 241, 116, 0.2)"
                          : undefined,
                    }}
                    onClick={() => handleRowClick(log.id)}
                  >
                    <TableCell sx={queryCellStyles}>{log.query}</TableCell>
                    <TableCell
                      onClick={() => {
                        window.open(
                          `https://yoderby.github.io/med-helper/?type=showlogs&id=${log.originalSessionId}`,
                          "_blank"
                        );
                      }}
                      sx={{ ...queryCellStyles, color: "blue" }}
                    >
                      {log.ipData ? safeParse(log.ipData).ip : " "}
                    </TableCell>
                    <TableCell sx={queryCellStyles}>
                      {log.ipData ? safeParse(log.ipData).city : " "}
                    </TableCell>
                    <TableCell sx={queryCellStyles}>
                      {formatTime(log.id)}
                    </TableCell>
                    <TableCell sx={{ ...queryCellStyles, fontSize: "7px" }}>
                      {log?.userAgent?.slice(0, 100)}
                    </TableCell>
                  </TableRow>
                  {expandedLog === log.id && (
                    <TableRow sx={logStyles.row}>
                      <TableCell colSpan={5} sx={logStyles.detailText}>
                        <Box
                          sx={{
                            width: { xs: "95vw", sm: "800px" },
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            direction: "rtl",
                            textAlign: "right",
                            margin: "25px",
                          }}
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeKatex]}
                          >
                            {`Query: \n\n` + log.headline}
                          </ReactMarkdown>
                          <ConversationHistory
                            onFeedback={handleFeedback}
                            cardRTL={true}
                            islogs={true}
                            conversationHistory={[
                              {
                                role: "user",
                                content: log.query ?? " ",
                              },
                              {
                                role: "system",
                                content: log.response ?? " ",
                              },
                            ]}
                          />
                        </Box>
                        <Box>{JSON.stringify(log)}</Box>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const renderChatsTable = () => {
    return (
      <>
        <Typography variant="h5">Chats</Typography>

        <TableContainer component={Paper} sx={logStyles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={logStyles.headerCell}>Timestamp</TableCell>
                <TableCell sx={logStyles.headerCell}>Query</TableCell>
                <TableCell sx={logStyles.headerCell}># of messages</TableCell>
                <TableCell sx={logStyles.headerCell}>ip</TableCell>
                <TableCell sx={logStyles.headerCell}>city</TableCell>
                <TableCell sx={logStyles.headerCell}>Mobile</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {logs.map((log) => (
                <React.Fragment key={log.id}>
                  <TableRow
                    sx={{
                      ...logStyles.row,
                      backgroundColor: log.shared
                        ? "rgba(235, 199, 199,0.2)"
                        : log.WhatsappShared
                          ? "rgba(48, 241, 116, 0.2)"
                          : undefined,
                    }}
                    onClick={() => handleRowClick(log.id)}
                  >
                    <TableCell sx={queryCellStyles}>{log.headline}</TableCell>
                    <TableCell sx={queryCellStyles}>
                      {formatTime(log.id)}
                    </TableCell>
                    <TableCell sx={queryCellStyles}>
                      {log.chatHistory.length}
                    </TableCell>

                    <TableCell
                      onClick={() => {
                        window.open(
                          `https://yoderby.github.io/med-helper/?type=showlogs&id=${log.originalSessionId}`,
                          "_blank"
                        );
                      }}
                      sx={{ ...queryCellStyles, color: "blue" }}
                    >
                      {" "}
                      {log.ipData ? JSON.parse(log.ipData).ip : " "}
                    </TableCell>
                    <TableCell sx={queryCellStyles}>
                      {log.ipData ? safeParse(log.ipData).city : " "}
                    </TableCell>
                    <TableCell sx={{ ...queryCellStyles, fontSize: "7px" }}>
                      {log?.userAgent?.slice(0, 100)}
                    </TableCell>
                  </TableRow>
                  {expandedLog === log.id && (
                    <TableRow sx={logStyles.row}>
                      <TableCell colSpan={3} sx={logStyles.detailText}>
                        <Box
                          sx={{
                            width: { xs: "95vw", sm: "800px" },
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            direction: "rtl",
                            textAlign: "right",
                            margin: "25px",
                          }}
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeKatex]}
                          >
                            {`Query: \n\n` + log.headline}
                          </ReactMarkdown>

                          <ConversationHistory
                            onFeedback={handleFeedback}
                            cardRTL={true}
                            islogs={true}
                            conversationHistory={log.chatHistory}
                          />
                        </Box>
                        <Box>{JSON.stringify(log)}</Box>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const renderVisitsTable = () => {
    return (
      <>
        <Typography variant="h5">Visits</Typography>

        <TableContainer component={Paper} sx={logStyles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={logStyles.headerCell}>Timestamp</TableCell>
                <TableCell sx={logStyles.headerCell}>ip</TableCell>
                <TableCell sx={logStyles.headerCell}>page</TableCell>
                <TableCell sx={logStyles.headerCell}>city</TableCell>
                <TableCell sx={logStyles.headerCell}>Mobile</TableCell>
                <TableCell sx={logStyles.headerCell}>Provider</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedLogs.map((log) => {
                if (isLog(log)) {
                  return (
                    <React.Fragment key={log.id}>
                      <TableRow
                        sx={{
                          ...logStyles.row,
                        }}
                      >
                        <TableCell sx={queryCellStyles}>
                          {formatTime(log.timestamp)}
                        </TableCell>

                        <TableCell sx={queryCellStyles}>
                          {log.data?.ip ?? " "}
                        </TableCell>
                        <TableCell sx={queryCellStyles}>
                          {log.data.VisitInTestSolutions ? "Panels" : "Website"}
                        </TableCell>
                        <TableCell sx={queryCellStyles}>
                          {log.data?.city ?? " "}
                        </TableCell>
                        <TableCell sx={{ ...queryCellStyles, fontSize: "7px" }}>
                          {log?.userAgent?.slice(0, 100)}
                        </TableCell>
                        <TableCell sx={queryCellStyles}>
                          {log.data?.org}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                } else {
                  console.log(isLog(log) ? "" : log.id);

                  return (
                    <React.Fragment key={log.id}>
                      <TableRow
                        sx={{
                          ...logStyles.row,
                        }}
                      >
                        <TableCell sx={queryCellStyles}>
                          {formatTime(log.id)}
                        </TableCell>

                        <TableCell sx={queryCellStyles}>
                          {log.ipData ? safeParse(log.ipData).ip : " "}
                        </TableCell>
                        <TableCell sx={queryCellStyles}>{"  Query"}</TableCell>

                        <TableCell sx={queryCellStyles}>
                          {log.ipData ? safeParse(log.ipData).city : " "}
                        </TableCell>
                        <TableCell sx={{ ...queryCellStyles, fontSize: "7px" }}>
                          {log?.userAgent?.slice(0, 100)}
                        </TableCell>
                        <TableCell sx={queryCellStyles}>{"  "}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const renderTestSolutionsTable = () => {
    return (
      <>
        <Typography variant="h5">Test Solutions</Typography>

        <TableContainer component={Paper} sx={logStyles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={logStyles.headerCell}>Timestamp</TableCell>
                <TableCell sx={logStyles.headerCell}>ip</TableCell>
                <TableCell sx={logStyles.headerCell}>city</TableCell>
                <TableCell sx={logStyles.headerCell}>userAgent</TableCell>

                <TableCell sx={logStyles.headerCell}># of Panels</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {solutionLogs.map((log, i) => (
                <React.Fragment key={i}>
                  <TableRow
                    sx={{
                      ...logStyles.row,
                    }}
                    onClick={() => handleRowClick(`${log.ip}-i`)}
                  >
                    <TableCell sx={queryCellStyles}>
                      {formatTime(log.panels[0].timestamp)}
                    </TableCell>
                    <TableCell sx={queryCellStyles}>{log.ip}</TableCell>
                    <TableCell sx={queryCellStyles}>{log.city}</TableCell>
                    <TableCell sx={queryCellStyles}>
                      {log.userAgent?.slice(0, 100)}
                    </TableCell>
                    <TableCell sx={queryCellStyles}>
                      {log.panels.length}
                    </TableCell>
                  </TableRow>
                  {expandedLog === `${log.ip}-i` && (
                    <TableRow sx={logStyles.row}>
                      <TableCell colSpan={3} sx={logStyles.detailText}>
                        {log.panels.map((p) => (
                          <Box>
                            {`${p.panel} \t\t\t ${formatTime(p.timestamp)}`}
                          </Box>
                        ))}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <Box>
      <Box>
        <Button
          onClick={handleSortByChatHistoryLength}
          variant="contained"
          style={{ marginBottom: "20px" }}
        >
          Sort by Number of Messages
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            marginBottom: 2,
          }}
        >
          <IconButton
            onClick={fetchUsageLogs}
            sx={{ color: "black", height: "50px", width: "50px" }}
          >
            <RefreshIcon />
          </IconButton>
          <Button
            variant="outlined"
            onClick={() => setSelectedTable("instantLogs")}
          >
            Instant Logs
          </Button>
          <Button variant="outlined" onClick={() => setSelectedTable("chats")}>
            Chats
          </Button>
          <Button variant="outlined" onClick={() => setSelectedTable("visits")}>
            Visits
          </Button>
          <Button
            variant="outlined"
            onClick={() => setSelectedTable("testSolutions")}
          >
            Test Solutions
          </Button>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          renderSelectedTable()
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></Box>
    </Box>
  );
};

const formatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // month is 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  return `${day}-${month}-${year % 1000}--${hour}:${minute}:${second}`;
};
export default LogsPage;

import { keyframes } from '@emotion/react';

// Define a blink keyframe animation
export const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
`;


export const componentStyles =
{

    mainBox: {
        color: 'white',
        alignItems: 'center',
        background: "linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%)",
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column'
    },
    innerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '70vh'
    },
    conversationBox: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: { xs: '100vw', sm: '75vw' },

    },
    cardUser: {
        background: "linear-gradient(60deg,rgba(255,255,255,0.11) 0%, rgba(255,255,255,0.11) 100%)",
        width: { xs: '85vw', sm: '65vw' },
        textAlign: 'right',
        minHeight: '120px',
        color: 'white',
        margin: '10px',
        padding: '10px',
        direction: 'rtl',
        maxWidth: '1200px',
        marginLeft: { xs: 'auto', sm: 'auto' }
    },
    cardModel: {
        background: "linear-gradient(60deg,rgba(255,255,255,0.61) 0%, rgba(255,255,255,0.61) 100%)",
        textAlign: 'right',
        width: { xs: '85vw', sm: '65vw' },
        minHeight: '120px',
        margin: '10px',
        padding: '10px',
        direction: 'rtl',
        maxWidth: '1200px',
        marginRight: { xs: 'auto', sm: 'auto' }

    },
    skeletonResponseCard: {
        background: "rgba(255,255,255,0.8)",
        width: { xs: '85vw', sm: '65vw' },
        minHeight: '120px',
        margin: '10px',
        padding: '10px',
        direction: 'rtl',
        maxWidth: '1200px',

    },
    partialResponseCard: {
        background: "linear-gradient(60deg,rgba(255,255,255,0.61) 0%, rgba(255,255,255,0.61) 100%)",
        width: { xs: '85vw', sm: '65vw' },
        minHeight: '120px',
        margin: '10px',
        padding: '10px',
        direction: 'rtl',
        maxWidth: '1200px',
    },
    loadingBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    linearProgressBox: {
        width: { xs: '80vw', sm: '100%' },
        padding: '25px'
    },
    thinkingText: {
        padding: '15px',
        direction: 'rtl',
        width: { xs: '80vw', sm: '100%' },
    },
    animatedThinkingText: {
        padding: '15px',
        direction: 'rtl',
        animation: `${blinkAnimation} 2.5s linear infinite`, // Apply the animation
    },
    TextSelectionStyle: {
        p: '15px',
        color: 'black',
        fontSize: { xs: '12px', sm: '14px' }
    },
    ChatSelectionStyle: {
        margin: '15px',
        display: 'flex', // Ensures buttons are in a row
        justifyContent: 'center', // Centers the buttons
        background: '#FFFFFF', // Light background for the group
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',// Subtle shadow

        '& .MuiToggleButtonGroup-grouped': {
            '&.Mui-selected': {
                backgroundColor: 'rgba(0, 123, 255, 0.15)', // Background color for selected button
                '&:hover': {
                    backgroundColor: 'rgba(0, 123, 255, 0.25)', // Background color on hover for selected button
                },
            },
            '&:not(.Mui-selected)': {
                backgroundColor: 'rgba(255, 255, 255, 0.15)', // Background color for unselected buttons
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.25)', // Background color on hover for unselected buttons
                },
            },
        },
    },
    submitButton: {
        '&:hover': {
            backgroundColor: 'rgba(196, 223, 255)', // Background color on hover for unselected buttons
        },
        background: 'white',
        m: '5px'
    },

    queryButton: {
        padding: '10px',
        fontSize: { xs: '14px', sm: '16px' },
        borderRadius: '8px',
    }

}
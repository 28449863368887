import { LOCAL_SERVER_ADDRESS } from "../Constants/consts";
import { ChatMessage, ChatSession } from "../Constants/interface";

export async function addFeedback(messageId: string, sessionId: string, isPositive: boolean) {
    try {
        const response = await fetch(`/api/feedback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                messageId,
                sessionId,
                feedback: isPositive ? 'positive' : 'negative'
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to add feedback');
        }

        return await response.json();
    } catch (error) {
        console.error('Error adding feedback:', error);
        throw error;
    }
}

export async function shareChat(sessionId: string) {
    try {
        const response = await fetch(`/api/share-chat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sessionId }),
        });

        if (!response.ok) {
            throw new Error('Failed to share chat');
        }

        return await response.json();
    } catch (error) {
        console.error('Error sharing chat:', error);
        throw error;
    }
}


export async function fetchChatSession(sessionId: string): Promise<ChatSession> {
    try {
        const response = await fetch(`${LOCAL_SERVER_ADDRESS}/api/get-shared-chat?session_id=${sessionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch chat session');
        }
        console.log("response is ", response);
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching chat session:', error);
        throw error;
    }
}


export async function createChatSession(metadata: any): Promise<string> {
    try {
        const response = await fetch(`/api/create-chat-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ metadata }),
        });

        if (!response.ok) {
            throw new Error('Failed to create chat session');
        }

        const data = await response.json();
        return data.sessionId;
    } catch (error) {
        console.error('Error creating chat session:', error);
        throw error;
    }
}

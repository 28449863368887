import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Stack,
  Modal,
  CircularProgress,
  Button,
} from "@mui/material";
import { CommentResponse } from "../Constants/interface";
import { Lecture, Term } from "../Constants/interface";
import { ANALYTICS_CATEGORIES, logCustomEvent, logEvent } from "../utils/Analytics";
import { createStyles } from "./styles";
import { styled } from "@mui/material/styles";
import {
  fetchSummary,
  fetchTerms,
  fetchTranscription,
} from "./Services/firebaseService";

// Component Imports
import TranscriptionPlayer from "./TranscriptionPlayer";
import Comments from "./Comments";
import Markdown from "./Markdown";
import ContentControls from "./ContentControls";
import { ReactToPDFComponent } from "./Tmp_savetopdf";
import { TOCItem } from "./TableOfContents";
import TableOfContents from "./TableOfContents";
import GoogleDocsIcon from "@mui/icons-material/Google";
import FloatingPlayer from "./FloatingPlayer";
import { ChunkedSegment } from "./TranscriptionPlayer";
import SummaryQuiz from "./SummaryQuiz";
// Types
interface ContentDisplayProps {
  view: "transcription" | "summary";
  setView: React.Dispatch<React.SetStateAction<"transcription" | "summary">>;
  length: "short" | "long";
  setLength: React.Dispatch<React.SetStateAction<"short" | "long">>;
  lecture: Lecture;
  playbackRate: number;
  setPlaybackRate: (rate: number) => void;
  setLecture: React.Dispatch<React.SetStateAction<Lecture | null>>;
}

// Styled Components
const ContentContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  maxWidth: "1000px",
  margin: "0 auto",
  padding: theme.spacing(2),
  paddingBottom: "120px",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0),
    paddingBottom: "120px",
    flex: 1,
    maxWidth: "100%",
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingTop: "40px",
  },
}));

const FloatingPlayerWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  zIndex: theme.zIndex.drawer + 1,
  background: 'transparent',
}));

const ContentDisplay: React.FC<ContentDisplayProps> = ({
  view,
  setView,
  length,
  setLength,
  setLecture,
  lecture,
  playbackRate,
  setPlaybackRate,
}) => {
  // State
  const [uiState, setUiState] = useState({
    showImages: true,
    fontSize: 1,
    isTocOpen: true,
    searchTerm: "",
  });

  const [loadingState, setLoadingState] = useState({
    summary: false,
    terms: false,
    transcription: false,
  });

  const [comments, setComments] = useState<CommentResponse[]>([]);
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const [tocState, setTocState] = useState<TOCItem[]>([]);
  const filteredTerms = lecture?.Terms?.filter(
    (term) =>
      term?.term?.toLowerCase().includes(uiState.searchTerm.toLowerCase()) ||
      false
  );
  const isLoading = Object.values(loadingState).some(Boolean);
  const audioRefObject = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [visibleChunks, setVisibleChunks] = useState<ChunkedSegment[]>([]);
  const [isFloatingPlayerVisible, setIsFloatingPlayerVisible] = useState(false);
  // Data Loading Functions
  const loadTranscription = async () => {
    if (lecture?.id && !lecture.TranscriptionText) {
      try {
        setLoadingState((prev) => ({ ...prev, transcription: true }));
        const data = await fetchTranscription(lecture.id);
        setLecture((prev) => ({
          ...prev!,
          TranscriptionText: data.segments,
        }));
      } catch (error) {
        console.error("Error loading transcription:", error);
      } finally {
        setLoadingState((prev) => ({ ...prev, transcription: false }));
      }
    }
  };

  const loadSummary = async () => {
    if (view === "summary" && lecture?.id && !lecture.TranscriptionSummary) {
      try {
        setLoadingState((prev) => ({ ...prev, summary: true }));
        const data = await fetchSummary(lecture.id);
        setLecture((prev) => ({
          ...prev!,
          TranscriptionSummary: data.fullSummary,
        }));
      } catch (error) {
        console.error("Error loading summary:", error);
      } finally {
        setLoadingState((prev) => ({ ...prev, summary: false }));
      }
    }
  };

  const loadTerms = async () => {
    if (
      view === "summary" &&
      length === "short" &&
      lecture?.id &&
      !lecture.Terms
    ) {
      try {
        setLoadingState((prev) => ({ ...prev, terms: true }));
        const data = await fetchTerms(lecture.id);
        setLecture((prev) => ({
          ...prev!,
          Terms: data.terms,
        }));
      } catch (error) {
        console.error("Error loading terms:", error);
      } finally {
        setLoadingState((prev) => ({ ...prev, terms: false }));
      }
    }
  };

  // Event Handlers
  const handleImageClick = (src: string) => {
    const formattedSrc = src.replace("\\\\", "/");
    logEvent(ANALYTICS_CATEGORIES.CONTENT, "image_enlarged", formattedSrc);
    setEnlargedImage(formattedSrc);
  };

  const handleFontSizeChange = (increase: boolean) => {
    const newSize = increase
      ? Math.min(uiState.fontSize + 0.1, 3)
      : Math.max(uiState.fontSize - 0.1, 0.1);

    setUiState((prev) => ({ ...prev, fontSize: newSize }));
    logEvent(ANALYTICS_CATEGORIES.CONTENT, `${increase ? "increase" : "decrease"}_font_size`, newSize.toString());
  };

  const handleTimeStampClick = (startTime: number) => {
    setIsFloatingPlayerVisible(true);
    if (audioRefObject.current) {
      if (audioRefObject.current.paused) {
        audioRefObject.current
          .play()
          .then(() => setIsPlaying(true))
          .catch((error) => console.error('Error playing audio:', error));
      }
      audioRefObject.current.currentTime = startTime;
      logCustomEvent(ANALYTICS_CATEGORIES.CONTENT, 'timestamp_navigation', {
        transcriptionName: lecture?.name,
        startTime: startTime.toString(),
      });
    }
  };

  useEffect(() => {
    loadTranscription();
  }, [view, lecture?.id]);
  useEffect(() => {
    loadSummary();
  }, [view, lecture?.id]);
  useEffect(() => {
    loadTerms();
  }, [view, length, lecture?.id]);

  const renderTermsList = () => {
    if (!filteredTerms?.length) return <Typography>No terms found.</Typography>;

    const styles = createStyles(uiState.fontSize, length === "short");
    return filteredTerms.map((term: Term, index: number) => (
      <Box key={index} mb={2} sx={styles.markdownContainer}>
        <Box sx={styles.markdownTermTextTitle}>{term.term}</Box>
        <Box sx={styles.markdownTermText}>{term.definition}</Box>
        <Box sx={styles.markdownTermText}>
          <strong>מונחים קשורים</strong>:{" "}
          {term.relatedTerms?.join(", ") || "None"}
        </Box>
        <Box sx={styles.markdownTermText}>
          <strong>הופעות</strong>: {term.appearances?.join(", ") || "None"}
        </Box>
      </Box>
    ));
  };

  const renderSummaryContent = () => {
    const styles = createStyles(uiState.fontSize, length === "long");

    if (length === "short" && lecture.Terms?.length) {
      return (
        <Box sx={{ minHeight: "80vh" }}>
          <Box sx={{ ...styles.markdownTermTextTitle, marginBottom: "20px" }}>
            המונחים המופיעים בסיכום ({filteredTerms?.length} מונחים)
          </Box>
          {renderTermsList()}
        </Box>
      );
    }

    if (!lecture.TranscriptionSummary) {
      return <Typography>עדיין אין סיכום ...</Typography>;
    }

    return (
      <Markdown
        handleImageClick={handleImageClick}
        fontSize={uiState.fontSize}
        isLongForm={length === "long"}
        showImages={uiState.showImages}
        isTocOpen={uiState.isTocOpen}
        onTocItemsChange={setTocState}
      >
        {length === "long"
          ? lecture.TranscriptionSummary
          : lecture.ShortTranscriptionSummary ?? ""}
      </Markdown>
    );
  };

  // Extract controls to a separate component
  const renderControls = () => (
    <ContentControls
      {...uiState}
      view={view}
      setView={setView}
      length={length}
      setLength={setLength}
      onIncreaseFontSize={() => handleFontSizeChange(true)}
      onDecreaseFontSize={() => handleFontSizeChange(false)}
      onToggleImages={() =>
        setUiState((prev) => ({ ...prev, showImages: !prev.showImages }))
      }
      onToggleToc={() =>
        setUiState((prev) => ({ ...prev, isTocOpen: !prev.isTocOpen }))
      }
      isMobile={window.innerWidth < 768}
    />
  );

  const onToggleFloatingPlayer = () => {
    audioRefObject.current?.pause();
    setIsPlaying(false);
    setIsFloatingPlayerVisible(prev => !prev)
  }

  const getTranscriptionText = useCallback(() => {
    if (!lecture?.TranscriptionText) return [];
    return lecture.TranscriptionText.map(segment => `${segment.start} - ${segment.end}: ${segment.text}`);
  }, [lecture?.TranscriptionText]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ direction: "rtl", height: "100%" }}>
      {renderControls()}
      {view === "transcription" ? (
        <ContentWrapper>
          <ContentContainer>
            <TranscriptionPlayer
              transcription={lecture}
              playbackRate={playbackRate}
              setPlaybackRate={setPlaybackRate}
              audioRef={audioRefObject}
              onSegmentIndexChange={setCurrentSegmentIndex}
              onVisibleChunksChange={setVisibleChunks}
              onPlayingChange={setIsPlaying}
            />
          </ContentContainer>
        </ContentWrapper>
      ) : (
        <>
          <ContentWrapper>
            <TableOfContents
              items={tocState}
              activeId={tocState.length > 0 ? tocState[0].id : ""}
              collapsedHeaders={new Set()}
              toggleCollapse={() => { }}
              isOpen={uiState.isTocOpen}
              onClose={() => setUiState((prev) => ({ ...prev, isTocOpen: false }))}
              onTimeStampClick={handleTimeStampClick}
            />
            <ContentContainer>
              <FloatingPlayer
                transcription={lecture}
                audioRef={audioRefObject}
                totalDuration={lecture?.TranscriptionText?.[lecture.TranscriptionText.length - 1]?.end || "00:00:00"}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                playbackRate={playbackRate}
                setPlaybackRate={setPlaybackRate}
                currentSegmentIndex={currentSegmentIndex}
                visibleChunks={visibleChunks}
                visible={isFloatingPlayerVisible}
                onHide={onToggleFloatingPlayer}
              />
              <Comments
                comments={comments}
                lectureId={lecture?.id?.toString() ?? "unknown"}
                setComments={setComments}
                lecture={lecture}
              />
              <SummaryQuiz
                lectureId={lecture.id}
                onTimeStampClick={handleTimeStampClick}
              />
              <Card
                sx={{
                  padding: { xs: 0.5, sm: 2, md: 3 },
                  maxWidth: "1200px",
                }}
              >
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    endIcon={<GoogleDocsIcon sx={{ pr: 1 }} />}
                    href={lecture?.docsUrl || "#"}
                    target="_blank"
                    disabled={!lecture?.docsUrl}
                    sx={{ direction: "rtl" }}
                    onClick={() => {
                      logEvent(ANALYTICS_CATEGORIES.CONTENT, "open_google_docs", lecture?.name);
                    }}
                  >
                    פתח בגוגל דוקס
                  </Button>
                </Stack>

                <CardContent sx={{ direction: "rtl", textAlign: "right" }}>
                  {length !== "long" ? (
                    <>
                      <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Search Terms"
                          value={uiState.searchTerm}
                          onChange={(e) =>
                            setUiState((prev) => ({
                              ...prev,
                              searchTerm: e.target.value,
                            }))
                          }
                          sx={{ mb: 2 }}
                        />
                        {renderSummaryContent()}
                      </Stack>
                    </>
                  ) : (
                    <>
                      <div id="pdf-content">
                        <Markdown
                          handleImageClick={handleImageClick}
                          fontSize={uiState.fontSize}
                          isLongForm={true}
                          showImages={uiState.showImages}
                          isTocOpen={uiState.isTocOpen}
                          onTocItemsChange={setTocState}
                          onTimeStampClick={handleTimeStampClick}
                        >
                          {lecture?.TranscriptionSummary}
                        </Markdown>
                      </div>
                    </>
                  )}
                </CardContent>
              </Card>


            </ContentContainer>

            {enlargedImage && (
              <Modal open={true} onClose={() => setEnlargedImage(null)}>
                <Box
                  sx={{
                    boxShadow: 24,
                    maxWidth: { xs: "90vw", md: "70vw" },
                    alignItems: "center",
                  }}
                >
                  <img
                    src={enlargedImage}
                    alt="Enlarged"
                    style={{ width: "100%", alignItems: "center" }}
                  />
                </Box>
              </Modal>
            )}
          </ContentWrapper>
        </>
      )}
    </Box>
  );
};

export default ContentDisplay;

import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    IconButton,
    Paper,
    Slider,
    Typography,
    Tooltip,
    Fade,
    Portal,
    useTheme,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { createChunkedSegments, parseTimeValue, timeStringToSeconds } from './TranscriptionPlayer';
import { ChunkedSegment } from './TranscriptionPlayer';
import { Lecture, TranscriptionSegment } from '../Constants/interface';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface WordTiming {
    word: string;
    start: number;
    end: number;
}

interface FloatingPlayerProps {
    transcription: Lecture;
    audioRef: React.RefObject<HTMLAudioElement>;
    totalDuration: string;
    isPlaying: boolean;
    setIsPlaying: (playing: boolean) => void;
    playbackRate: number;
    setPlaybackRate: (rate: number) => void;
    currentSegmentIndex: number;
    visibleChunks: ChunkedSegment[];
    visible?: boolean;
    onHide: () => void;

}

const formatTimeStamp = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(':');
    return `${hours}:${minutes}:${seconds.split('.')[0]}`;
};

const FloatingPlayer: React.FC<FloatingPlayerProps> = ({
    transcription,
    audioRef,
    totalDuration,
    isPlaying,
    setIsPlaying,
    playbackRate,
    setPlaybackRate,
    currentSegmentIndex: c,
    visibleChunks: v,
    visible = true,
    onHide,
}) => {
    const theme = useTheme();
    const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
    const [visibleChunks, setVisibleChunks] = useState<ChunkedSegment[]>([]);
    const [currentTime, setCurrentTime] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [processedSegments, setProcessedSegments] = useState<TranscriptionSegment[]>([]);
    const totalDurationSeconds = timeStringToSeconds(totalDuration);
    const [isExpanded, setIsExpanded] = useState(false);
    const [deltaPosition, setDeltaPosition] = useState({
        x: 0,
        y: 0
    });
    const [dragChanges, setDragChanges] = useState({ x: 0, y: 0 });
    const isDraggingRef = useRef(false);
    const expandedViewRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        console.log(transcription)
        if (transcription.TranscriptionText) {
            const processed = transcription.TranscriptionText.map((segment) => ({
                ...segment,
                start: segment.start,
                end: segment.end,
            }));
            console.log(processed)
            setProcessedSegments(processed)
            setVisibleChunks(createChunkedSegments(processed, 1, false))
        }
        console.log(visibleChunks)
    }, [transcription.TranscriptionText]);

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (audioRef.current) {
                const newCurrentTime = audioRef.current.currentTime;
                setCurrentTime(newCurrentTime);

                // Find the current segment based on the audio time
                const newIndex = processedSegments.findIndex((segment, index) => {
                    const startTime = parseTimeValue(segment.start);
                    const endTime = parseTimeValue(segment.end);

                    // If current segment is an image, check if there's a next segment
                    if (segment.isImage && index < processedSegments.length - 1) {
                        const nextSegment = processedSegments[index + 1];
                        const nextStartTime = parseTimeValue(nextSegment.start);

                        // If we're past the next segment's start time, skip this image segment
                        if (newCurrentTime >= nextStartTime) {
                            return false;
                        }
                    }

                    return newCurrentTime >= startTime && newCurrentTime < endTime;
                });

                // If we found a valid segment and it's different from the current one
                if (newIndex !== -1 && newIndex !== currentSegmentIndex) {
                    setCurrentSegmentIndex(newIndex);
                }
            }
        };

        const audioElement = audioRef.current;
        audioElement?.addEventListener("timeupdate", handleTimeUpdate);

        return () => {
            audioElement?.removeEventListener("timeupdate", handleTimeUpdate);
        };
    }, [processedSegments, currentSegmentIndex]);


    useEffect(() => {
        console.log(
            "TranscriptionPlayer, transcription: ",
            transcription.audioFileId
        );
        if (
            transcription.audioFileId &&
            !transcription.audioFileId.endsWith(".mp3")
        ) {
            transcription.audioFileId += ".mp3";
        }
        const lastSegment =
            transcription.TranscriptionText?.[
            transcription.TranscriptionText.length - 1, transcription.TranscriptionText.length - 1
            ];
    }, [transcription]);
    const findNearestTextSegment = (index: number, direction: 'prev' | 'next'): ChunkedSegment | null => {
        if (!visibleChunks || !visibleChunks.length) return null;

        if (direction === 'prev') {
            for (let i = index - 1; i >= 0; i--) {
                if (visibleChunks[i] && !visibleChunks[i].isImage) {
                    return visibleChunks[i];
                }
            }
        } else {
            for (let i = index + 1; i < visibleChunks.length; i++) {
                if (visibleChunks[i] && !visibleChunks[i].isImage) {
                    return visibleChunks[i];
                }
            }
        }
        return null;
    };

    const currentSegment = processedSegments[currentSegmentIndex]?.isImage ? null : processedSegments[currentSegmentIndex];
    const previousSegment = currentSegment ? findNearestTextSegment(currentSegmentIndex, 'prev') : null;
    const nextSegment = currentSegment ? findNearestTextSegment(currentSegmentIndex, 'next') : null;
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = playbackRate;
        }
    }, [playbackRate]);

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (audioRef.current && !isDragging) {
                setCurrentTime(audioRef.current.currentTime);
            }
        };

        const audioElement = audioRef.current;
        audioElement?.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            audioElement?.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [audioRef, isDragging]);

    const handlePlayPause = () => {
        if (!audioRef.current) return;

        if (audioRef.current.paused) {
            audioRef.current
                .play()
                .then(() => setIsPlaying(true))
                .catch((error) => console.error('Error playing audio:', error));
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleSpeedChange = (increment: boolean) => {
        console.log(playbackRate)
        const newRate = increment ? playbackRate + 0.25 : playbackRate - 0.25;
        setPlaybackRate(Math.max(0.5, Math.min(5, Number(newRate.toFixed(2)))));
    };

    const formatTime = (seconds: number) => {
        const pad = (num: number) => num.toString().padStart(2, '0');
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    };

    const handleTimeChange = (_: Event, newValue: number | number[]) => {
        const newTime = newValue as number;
        setCurrentTime(newTime);
        if (audioRef.current) {
            audioRef.current.currentTime = newTime;
        }
    };

    const calculateWordTimings = (segment: TranscriptionSegment): WordTiming[] => {
        if (!segment || !segment.text) return [];

        const words = segment.text.split(' ');
        const segmentStart = parseTimeValue(segment.start);
        const segmentEnd = parseTimeValue(segment.end);
        const duration = segmentEnd - segmentStart;
        const timePerWord = duration / words.length;

        return words.map((word, index) => ({
            word,
            start: segmentStart + (index * timePerWord),
            end: segmentStart + ((index + 1) * timePerWord)
        }));
    };


    const handleDrag = (_e: any, data: { x: number; y: number }) => {
        setDeltaPosition({ x: data.x, y: data.y });
    };

    useEffect(() => {
        if (isExpanded && expandedViewRef.current) {
            const container = expandedViewRef.current;
            const currentElement = container.children[currentSegmentIndex];

            if (currentElement) {
                currentElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }
    }, [currentSegmentIndex, isExpanded]);

    const handleExpand = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();  // Prevent event bubbling
        console.log('Expand clicked, current state:', isExpanded);
        setIsExpanded(prev => !prev);
    };

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();  // Prevent event bubbling
        console.log('Close clicked');
        if (onHide) {
            onHide();
        }
    };

    return (
        <Portal>
            <Draggable
                position={deltaPosition}
                onDrag={handleDrag}
                handle=".drag-handle"
                cancel=".click-action" // Add this to prevent Draggable from interfering with buttons
                bounds="body"
            >
                <Paper
                    elevation={3}
                    sx={{
                        position: 'fixed',
                        top: isExpanded ? { xs: '10%', sm: '25%' } : { xs: '60%', sm: '70%' },
                        right: { xs: '5%', sm: '30%' },
                        left: { xs: '5%', sm: 'auto' },
                        zIndex: theme.zIndex.modal,
                        width: { xs: '90%', sm: '90%' },
                        maxWidth: { xs: '100%', sm: 800 },
                        padding: { xs: 1, sm: 2 },
                        backgroundColor: 'rgba(255, 255, 255, 0.98)',
                        backdropFilter: 'blur(10px)',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        display: visible ? 'block' : 'none',
                        '&:hover': {
                            boxShadow: '0 6px 24px rgba(0, 0, 0, 0.2)',
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 1, sm: 2 } }}>
                        <Box
                            className="drag-handle"
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 1,
                                cursor: 'move'
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: 1, mr: 'auto' }}>
                                <IconButton
                                    className="click-action"
                                    size="small"
                                    onClick={handleClose}
                                    sx={{
                                        '&:active': { transform: 'scale(0.95)' },
                                        transition: 'transform 0.1s',
                                        zIndex: theme.zIndex.modal + 1 // Ensure button is clickable
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <IconButton
                                    className="click-action"
                                    size="small"
                                    onClick={handleExpand}
                                    sx={{
                                        '&:active': { transform: 'scale(0.95)' },
                                        transition: 'transform 0.1s',
                                        zIndex: theme.zIndex.modal + 1 // Ensure button is clickable
                                    }}
                                >
                                    {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                </IconButton>
                            </Box>
                            <DragIndicatorIcon color="action" />
                        </Box>

                        <Box
                            ref={expandedViewRef}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                padding: { xs: 1, sm: 2 },
                                backgroundColor: 'rgba(245, 245, 245, 0.9)',
                                borderRadius: 1,
                                direction: 'rtl',
                                maxHeight: isExpanded ? '400px' : '100px',
                                overflowY: 'auto',
                                transition: 'max-height 0.3s ease-in-out',
                            }}
                        >
                            {isExpanded ? (
                                visibleChunks.map((segment, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            gap: { xs: 1, sm: 2 },
                                            flexDirection: { xs: 'column', sm: 'row' },
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: 'text.secondary',
                                                minWidth: { xs: '100%', sm: '70px' },
                                                textAlign: 'left',
                                                direction: 'ltr',
                                            }}
                                        >
                                            {formatTimeStamp(segment.start)}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                flex: 1,
                                                width: { xs: '80%', sm: 'auto' },
                                                fontSize: { xs: '0.875rem', sm: '1rem' },
                                                color: currentSegmentIndex === index ? 'primary.main' : 'text.primary',
                                                fontWeight: currentSegmentIndex === index ? 'bold' : 'normal',
                                                borderRight: currentSegmentIndex === index ? '3px solid' : 'none',
                                                borderColor: 'primary.main',
                                                paddingRight: 1,
                                                marginBottom: 1,
                                            }}
                                        >
                                            {segment.text}
                                        </Typography>
                                    </Box>
                                ))
                            ) : (
                                <>
                                    {previousSegment && (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            gap: { xs: 1, sm: 2 },
                                            flexDirection: { xs: 'column', sm: 'row' }
                                        }}>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: 'text.secondary',
                                                    minWidth: { xs: '100%', sm: '70px' },
                                                    textAlign: 'left',
                                                    direction: 'ltr',
                                                }}
                                            >
                                                {formatTimeStamp(previousSegment.start)}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    flex: 1,
                                                    width: { xs: '100%', sm: 'auto' },
                                                    fontSize: { xs: '0.875rem', sm: '1rem' },
                                                    color: 'text.secondary',
                                                    opacity: 0.7
                                                }}
                                            >
                                                {previousSegment.text}
                                            </Typography>
                                        </Box>
                                    )}
                                    {currentSegment && (
                                        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: 'text.secondary',
                                                    minWidth: '70px',
                                                    textAlign: 'left',
                                                    direction: 'ltr',
                                                }}
                                            >
                                                {formatTimeStamp(currentSegment.start)}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    flex: 1,
                                                    color: 'primary.main',
                                                    fontWeight: 'bold',
                                                    borderRight: '3px solid',
                                                    borderColor: 'primary.main',
                                                    paddingRight: 1,
                                                }}
                                            >
                                                {calculateWordTimings(currentSegment).map((wordTiming, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            color: currentTime >= wordTiming.start && currentTime < wordTiming.end
                                                                ? '#1976d2'
                                                                : 'inherit',
                                                            backgroundColor: currentTime >= wordTiming.start && currentTime < wordTiming.end
                                                                ? 'rgba(25, 118, 210, 0.1)'
                                                                : 'transparent',
                                                            padding: '0 2px',
                                                            borderRadius: '2px',
                                                            transition: 'all 0.2s ease'
                                                        }}
                                                    >
                                                        {wordTiming.word}{' '}
                                                    </span>
                                                ))}
                                            </Typography>
                                        </Box>
                                    )}
                                    {nextSegment && (
                                        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: 'text.secondary',
                                                    minWidth: '70px',
                                                    textAlign: 'left',
                                                    direction: 'ltr',
                                                }}
                                            >
                                                {formatTimeStamp(nextSegment.start)}
                                            </Typography>
                                            <Typography variant="body2" sx={{ flex: 1, color: 'text.secondary', opacity: 0.5 }}>
                                                {nextSegment.text}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: { xs: 1, sm: 2 },
                            flexDirection: 'row'
                        }}>
                            <IconButton
                                onClick={handlePlayPause}
                                sx={{
                                    backgroundColor: 'rgb(66, 133, 244)',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'rgb(56, 123, 234)',
                                    },
                                    width: { xs: 36, sm: 40 },
                                    height: { xs: 36, sm: 40 },
                                }}
                            >
                                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <Box sx={{
                                flex: 1,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                <Slider
                                    size="small"
                                    value={currentTime}
                                    max={totalDurationSeconds}
                                    onChange={handleTimeChange}
                                    onMouseDown={() => setIsDragging(true)}
                                    onMouseUp={() => setIsDragging(false)}
                                    aria-label="time-slider"
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <Typography variant="caption">
                                        {formatTime(currentTime)}
                                    </Typography>
                                    <Typography variant="caption">
                                        {totalDuration}
                                    </Typography>

                                </Box>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: { sm: 'auto' },
                                justifyContent: 'flex-start'
                            }}>
                                <Tooltip title="Decrease speed">
                                    <IconButton onClick={() => handleSpeedChange(false)} size="small">
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body2" sx={{ mx: 1 }}>
                                    {playbackRate.toFixed(2)}x
                                </Typography>
                                <Tooltip title="Increase speed">
                                    <IconButton onClick={() => handleSpeedChange(true)} size="small">
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                    <audio ref={audioRef} src={transcription.audioFileId} />
                </Paper>
            </Draggable>
        </Portal>
    );
};

export default FloatingPlayer;

import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Slider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import ImageIcon from "@mui/icons-material/Image";

import { Lecture, TranscriptionSegment } from "../Constants/interface";
import { ANALYTICS_CATEGORIES, logCustomEvent, logEvent } from "../utils/Analytics";
import FloatingPlayer from "./FloatingPlayer";

export interface TranscriptionPlayerProps {
  transcription: Lecture;
  playbackRate?: number;
  setPlaybackRate?: (rate: number) => void;
  audioRef?: React.RefObject<HTMLAudioElement>;
  onSegmentIndexChange?: (index: number) => void;
  onVisibleChunksChange?: (chunks: ChunkedSegment[]) => void;
  onPlayingChange?: (isPlaying: boolean) => void;
}
export const createChunkedSegments = (
  segments: TranscriptionSegment[],
  size: number,
  includeImages: boolean = true
): ChunkedSegment[] => {
  console.log(segments)
  const chunked: ChunkedSegment[] = [];
  let currentChunk: TranscriptionSegment[] = [];

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];

    // If we encounter an image
    if (segment.isImage) {
      // First, add any pending text chunk
      if (currentChunk.length > 0) {
        chunked.push({
          ...currentChunk[0],
          text: currentChunk.map(seg => seg.text).join(" "),
          end: currentChunk[currentChunk.length - 1].end,
          originalSegments: [...currentChunk]
        });
        currentChunk = [];
      }
      if (includeImages) {
        // Add the image as a standalone chunk
        chunked.push({
          ...segment,
          originalSegments: [segment]
        });
      }
      continue;
    }

    // Handle text segments
    currentChunk.push(segment);

    // Create a chunk if we've reached the size limit or it's the last segment
    if (currentChunk.length === size || i === segments.length - 1) {
      chunked.push({
        ...currentChunk[0],
        text: currentChunk.map(seg => seg.text).join(" "),
        end: currentChunk[currentChunk.length - 1].end,
        originalSegments: [...currentChunk]
      });
      currentChunk = [];
    }
  }

  // Handle any remaining segments in the last chunk
  if (currentChunk.length > 0) {
    chunked.push({
      ...currentChunk[0],
      text: currentChunk.map(seg => seg.text).join(" "),
      end: currentChunk[currentChunk.length - 1].end,
      originalSegments: [...currentChunk]
    });
  }

  return chunked;
};
export const parseTimeValue = (time: string | number): number => {
  if (typeof time === "string") {
    // If it's a timestamp string (HH:MM:SS)
    if (time.includes(":")) {
      return timeStringToSeconds(time);
    }
    // If it's a string number
    return parseFloat(time);
  }
  return time;
};

export interface ChunkedSegment extends TranscriptionSegment {
  originalSegments: TranscriptionSegment[];
}

export function timeStringToSeconds(timeString: string): number {
  const [hours, minutes, seconds] = timeString.split(":").map(parseFloat);
  return hours * 3600 + minutes * 60 + seconds;
}

const TranscriptionPlayer: React.FC<TranscriptionPlayerProps> = (
  {
    transcription,
    playbackRate: initialPlaybackRate,
    audioRef: externalAudioRef,
    onSegmentIndexChange,
    onVisibleChunksChange,
    onPlayingChange,
  }

) => {
  const [playbackRate, setPlaybackRate] = useState(initialPlaybackRate || 1);
  const [totalDuration, setTotalDuration] = useState("");

  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [visibleChunks, setVisibleChunks] = useState<ChunkedSegment[]>([]);
  const [chunkSize, setChunkSize] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const itemsPerPage = 10;
  const audioRef = useRef<HTMLAudioElement>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<
    { index: number; matches: number[] }[]
  >([]);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [currentSearchIndex, setCurrentSearchIndex] = useState(-1);

  const [processedSegments, setProcessedSegments] = useState<TranscriptionSegment[]>([]);
  console.log("processedSegments - TranscriptionPlayer", processedSegments);
  useEffect(() => {
    logEvent(ANALYTICS_CATEGORIES.PLAYER, "loaded_player", transcription.name);
  }, [transcription]);

  useEffect(() => {
    console.log(
      "TranscriptionPlayer, transcription: ",
      transcription.audioFileId
    );
    if (
      transcription.audioFileId &&
      !transcription.audioFileId.endsWith(".mp3")
    ) {
      transcription.audioFileId += ".mp3";
    }
    const lastSegment =
      transcription.TranscriptionText?.[
      transcription.TranscriptionText.length - 1, transcription.TranscriptionText.length - 1
      ];
    setTotalDuration(lastSegment?.end || "0");
    // convert start and end to
  }, [transcription]);

  useEffect(() => {
    if (transcription.TranscriptionText) {
      const processed = transcription.TranscriptionText.map((segment) => ({
        ...segment,
        start: segment.start,
        end: segment.end,
      }));
      setProcessedSegments(processed);

      const chunkedSegments = createChunkedSegments(processed, chunkSize);
      setVisibleChunks(chunkedSegments);
      setHasMore(chunkedSegments.length > itemsPerPage);
    }
  }, [transcription.TranscriptionText, chunkSize]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        const newCurrentTime = audioRef.current.currentTime;
        console.log(newCurrentTime)
        setCurrentTime(newCurrentTime);

        // Find the current segment based on the audio time
        const newIndex = processedSegments.findIndex((segment, index) => {
          const startTime = parseTimeValue(segment.start);
          const endTime = parseTimeValue(segment.end);

          // If current segment is an image, check if there's a next segment
          if (segment.isImage && index < processedSegments.length - 1) {
            const nextSegment = processedSegments[index + 1];
            const nextStartTime = parseTimeValue(nextSegment.start);

            // If we're past the next segment's start time, skip this image segment
            if (newCurrentTime >= nextStartTime) {
              return false;
            }
          }

          return newCurrentTime >= startTime && newCurrentTime < endTime;
        });

        // If we found a valid segment and it's different from the current one
        if (newIndex !== -1 && newIndex !== currentSegmentIndex) {
          setCurrentSegmentIndex(newIndex);
          scrollToSegment(newIndex);
        }
      }
    };

    const audioElement = audioRef.current;
    audioElement?.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audioElement?.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [processedSegments, currentSegmentIndex]);

  const getTranscriptionSegments = useCallback((): TranscriptionSegment[] => {
    return processedSegments;
  }, [processedSegments]);

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const term = event.target.value.trim();
      setSearchTerm(term);
      if (term === "") {
        setSearchResults([]);
        setCurrentSearchIndex(-1);
        return;
      }

      const results = visibleChunks.reduce(
        (acc: { index: number; matches: number[] }[], chunk, index) => {
          const matches: number[] = [];
          let match;
          const searchRegex = new RegExp(escapeRegExp(term), "gi");

          while ((match = searchRegex.exec(chunk.text)) !== null) {
            matches.push(match.index);
          }

          if (matches.length > 0) {
            acc.push({ index, matches });
          }
          return acc;
        },
        []
      );

      setSearchResults(results);
      setCurrentSearchIndex(results.length > 0 ? 0 : -1);
    },
    [visibleChunks]
  );

  // Helper function to escape special characters in the search term
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };
  /**
   * Jumps to the specified search result index.
   *
   * @param resultIndex - The index of the search result to jump to.
   */
  const jumpToSearchResult = (resultIndex: number) => {
    if (
      searchResults.length > 0 &&
      resultIndex >= 0 &&
      resultIndex < searchResults.length
    ) {
      setCurrentSearchIndex(resultIndex);

      const targetChunkIndex = searchResults[resultIndex].index;
      setCurrentSegmentIndex(targetChunkIndex);

      scrollToSegment(targetChunkIndex);
    }
  };

  const jumpToNextResult = useCallback(() => {
    if (searchResults.length > 0) {
      const nextIndex = (currentSearchIndex + 1) % searchResults.length;
      jumpToSearchResult(nextIndex);
    }
  }, [searchResults.length, currentSearchIndex]);

  const jumpToPreviousResult = useCallback(() => {
    if (searchResults.length > 0) {
      const prevIndex =
        (currentSearchIndex - 1 + searchResults.length) % searchResults.length;
      jumpToSearchResult(prevIndex);
    }
  }, [searchResults.length, currentSearchIndex]);

  const scrollToSegment = (index: number) => {
    const element = document.getElementById(`segment-${index}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const highlightText = (text: string, matches: number[]) => {
    if (matches.length === 0) return text;
    let result = [];
    let lastIndex = 0;

    const words = searchTerm.split(/\s+/);
    const useExactMatch = words.length > 1;

    const highlightRegex = useExactMatch
      ? new RegExp(escapeRegExp(searchTerm), "gi")
      : new RegExp(
        words.map((word) => `(${escapeRegExp(word)})`).join("|"),
        "gi"
      );

    matches.forEach((matchIndex) => {
      result.push(text.slice(lastIndex, matchIndex));
      const remainingText = text.slice(matchIndex);
      const match = highlightRegex.exec(remainingText);
      if (match) {
        result.push(
          <span
            key={matchIndex}
            style={{ backgroundColor: "yellow", fontWeight: "bold" }}
          >
            {match[0]}
          </span>
        );
        lastIndex = matchIndex + match[0].length;
      }
    });
    result.push(text.slice(lastIndex));
    return result;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.shiftKey) {
        jumpToPreviousResult();
      } else {
        jumpToNextResult();
      }
    }
  };





  const togglePlayPause = (segment: ChunkedSegment, index: number) => {
    if (!audioRef.current) return;

    logCustomEvent(ANALYTICS_CATEGORIES.PLAYER, "player_event", {
      'label': segment.id,
      'subject': transcription?.name || "",
      'transcriptionName': transcription?.id || "",
      'time': currentTime,
    });

    if (currentSegmentIndex !== index) {
      const startTime = parseTimeValue(segment.start);

      // Validate the time value before setting it
      if (isFinite(startTime) && startTime >= 0) {
        audioRef.current.currentTime = startTime;
        setCurrentSegmentIndex(index);
      } else {
        console.error("Invalid start time:", segment.start);
        return;
      }
    }

    if (audioRef.current.paused) {
      audioRef.current
        .play()
        .then(() => setIsPlaying(true))
        .catch((error) => console.error("Error playing audio:", error));
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleSpeedChange = (increment: boolean) => {
    setPlaybackRate((prevRate) => {
      const newRate = increment ? prevRate + 0.25 : prevRate - 0.25;
      return Math.max(0.5, Math.min(5, Number(newRate.toFixed(2))));
    });
  };

  const calculateProgress = (chunk: ChunkedSegment | TranscriptionSegment, index: number) => {
    const startTime = parseTimeValue(chunk.start);
    const endTime = parseTimeValue(chunk.end);
    const chunkDuration = endTime - startTime;

    if (index === currentSegmentIndex) {
      const chunkProgress = Math.min(currentTime - startTime, chunkDuration);
      return (chunkProgress / chunkDuration) * 100;
    }

    return index < currentSegmentIndex ? 100 : 0;
  };

  useEffect(() => {
    const segments = getTranscriptionSegments();
    if (segments.length > 0) {
      const lastSegment = segments[segments.length - 1];
    }
  }, [processedSegments]);

  const handleChunkSizeChange = (event: Event, newValue: number | number[]) => {
    setChunkSize(newValue as number);
  };

  const formatTime = (seconds: number) => {
    const pad = (num: number) => num.toString().padStart(2, "0");
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  };

  useEffect(() => {
    const handleGlobalKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && event.target !== searchInputRef.current) {
        event.preventDefault();
        if (event.shiftKey) {
          jumpToPreviousResult();
        } else {
          jumpToNextResult();
        }
      }
    };

    window.addEventListener("keydown", handleGlobalKeyPress);
    return () => window.removeEventListener("keydown", handleGlobalKeyPress);
  }, [jumpToNextResult, jumpToPreviousResult]);

  const renderListItems = useCallback(() => {
    const chunkStartIndex =
      Math.floor(currentSegmentIndex / chunkSize) * chunkSize;

    return visibleChunks
      .filter((segment) => !segment.originalSegments.some((seg) => seg.isImage)) // Filter out segments with path
      .map((segment, index) => {
        const globalIndex = chunkStartIndex + index;
        const searchResult = searchResults.find(
          (result) => result.index === globalIndex
        );
        const isCurrentSearchResult =
          searchResults[currentSearchIndex]?.index === globalIndex;

        return (
          <ListItem
            id={`segment-${index}`}
            key={segment.id}
            sx={{
              bgcolor: isCurrentSearchResult
                ? "lightyellow" // Changed from "lightyellow" to "yellow" for more visibility
                : index === currentSegmentIndex
                  ? "lightgray"
                  : "inherit",
              display: "flex",
              flexDirection: "row-reverse",
              direction: "rtl",
              textAlign: "right",
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body1">
                  {searchResult
                    ? highlightText(segment.text, searchResult.matches)
                    : segment.text}
                </Typography>
              }
              secondary={
                <>
                  {`${formatTime(parseTimeValue(segment.start))} - ${formatTime(
                    parseTimeValue(segment.end)
                  )}`}
                  {index === currentSegmentIndex && isPlaying && (
                    <LinearProgress
                      variant="determinate"
                      value={calculateProgress(segment, index)}
                    />
                  )}
                </>
              }
            />
            <IconButton
              onClick={() => togglePlayPause(segment, index)}
              size="small"
              sx={{
                marginLeft: "15px",
                backgroundColor: "rgb(66, 133, 244)",
                color: "white",
              }}
            >
              {(segment.originalSegments as any).some(
                (segment: any) => segment.path
              ) ? (
                <ImageIcon />
              ) : index === currentSegmentIndex && isPlaying ? (
                <PauseIcon />
              ) : (
                <PlayArrowIcon />
              )}
            </IconButton>
          </ListItem>
        );
      });
  }, [
    visibleChunks,
    currentSegmentIndex,
    chunkSize,
    searchResults,
    currentSearchIndex,
    isPlaying,
  ]);

  useEffect(() => {
    if (onPlayingChange) {
      onPlayingChange(isPlaying);
    }
  }, [isPlaying, onPlayingChange]);

  return (
    <Box
      sx={{
        maxWidth: "1200px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "calc(100vh - 64px)", // Adjust based on your header height
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          padding: 2,
          borderBottom: "1px solid #ddd",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: { xs: "100%", sm: "40%" },
          }}
        >
          <Typography variant="subtitle2" sx={{ marginRight: 1 }}>
            גודל משפט:
          </Typography>
          <Slider
            size="small"
            value={chunkSize}
            onChange={handleChunkSizeChange}
            aria-labelledby="chunk-size-slider"
            valueLabelDisplay="auto"
            step={3}
            marks
            min={1}
            max={50}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "60%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold", mx: 1 }}>
              מהירות השמעה:
            </Typography>
            <IconButton onClick={() => handleSpeedChange(false)} size="small">
              <RemoveIcon />
            </IconButton>
            <Typography variant="body2" sx={{ mx: 1 }}>
              {playbackRate.toFixed(2)}x
            </Typography>
            <IconButton onClick={() => handleSpeedChange(true)} size="small">
              <AddIcon />
            </IconButton>
          </Box>
          <Typography
            variant="body2"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            משך הרצאה: {totalDuration}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            fullWidth
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={handleKeyPress}
            placeholder="חיפוש בתמלול"
            inputRef={searchInputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body2">
            {searchResults.length > 0
              ? `${currentSearchIndex + 1}/${searchResults.length}`
              : "0/0"}
          </Typography>
          <Tooltip title="Previous (Shift+Enter)">
            <span>
              <IconButton
                onClick={jumpToPreviousResult}
                disabled={searchResults.length === 0}
              >
                <Typography>↑</Typography>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Next (Enter)">
            <span>
              <IconButton
                onClick={jumpToNextResult}
                disabled={searchResults.length === 0}
              >
                <Typography>↓</Typography>
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>

      <Box
        ref={listRef}
        sx={{
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
          height: "auto",
          minHeight: 0,
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
            "&:hover": {
              background: "#555",
            },
          },
        }}
      >
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            padding: 0,
          }}
        >
          {visibleChunks.map((chunk, index) => (
            <ListItem
              id={`segment-${index}`}
              key={chunk.id}
              sx={{
                bgcolor: index === currentSegmentIndex
                  ? "lightgray"
                  : "inherit",
                display: "flex",
                flexDirection: "row-reverse",
                direction: "rtl",
                textAlign: "right",
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {searchResults.length > 0
                      ? highlightText(chunk.text, searchResults[currentSearchIndex].matches)
                      : chunk.isImage
                        ? `תמונה: ${chunk.text}`
                        : chunk.text}
                  </Typography>
                }
                secondary={
                  <>
                    {`${formatTime(parseTimeValue(chunk.start))} - ${formatTime(
                      parseTimeValue(chunk.end)
                    )}`}
                    {index === currentSegmentIndex && isPlaying && !chunk.isImage && (
                      <LinearProgress
                        variant="determinate"
                        value={calculateProgress(chunk, index)}
                      />
                    )}
                  </>
                }
              />
              <IconButton
                onClick={() => togglePlayPause(chunk, index)}
                size="small"
                sx={{
                  marginLeft: "15px",
                  backgroundColor: "rgb(66, 133, 244)",
                  color: "white",
                }}
              >
                {chunk.isImage ? (
                  <ImageIcon />
                ) : index === currentSegmentIndex && isPlaying ? (
                  <PauseIcon />
                ) : (
                  <PlayArrowIcon />
                )}
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Box>
      {/* 
      <FloatingPlayer
        transcription={transcription}
        audioRef={audioRef}
        totalDuration={totalDuration}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        playbackRate={playbackRate}
        setPlaybackRate={setPlaybackRate}
        currentSegmentIndex={currentSegmentIndex}
        visibleChunks={visibleChunks}
      /> */}

      <audio ref={audioRef} src={transcription.audioFileId} />
    </Box>
  );
};

export default TranscriptionPlayer;

import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  Typography,
  Divider,
  IconButton,
  Modal,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import { componentStyles } from "../styles";
import { ChatMessage } from "../Constants/interface";
import SmartToyTwoToneIcon from "@mui/icons-material/SmartToyTwoTone";
import PermIdentityTwoToneIcon from "@mui/icons-material/PermIdentityTwoTone";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { a } from "react-spring";
import { ImageModal, MarkdownImage } from "./ChatBox";

interface ConversationHistoryProps {
  conversationHistory: ChatMessage[];
  islogs?: boolean;
  cardRTL: boolean;
  onFeedback: (index: number, isPositive: boolean) => void;
}

const styles = componentStyles;

const ConversationHistory: React.FC<ConversationHistoryProps> = ({
  islogs,
  conversationHistory,
  cardRTL,
  onFeedback,
}) => {
  const [feedbackGiven, setFeedbackGiven] = useState<{
    [key: number]: boolean | null;
  }>({});
  const activeColor = "rgb(0, 110, 255)";
  const inactiveColor = "rgb(130, 182, 251)";
  const handleFeedback = (index: number, isPositive: boolean) => {
    setFeedbackGiven((prev) => ({ ...prev, [index]: isPositive }));
    onFeedback(index, isPositive);
  };
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  return (
    <Box
      sx={islogs ? { direction: cardRTL ? "rtl" : "" } : styles.conversationBox}
    >
      {conversationHistory.map((el, index) => (
        <React.Fragment key={index}>
          <Card
            sx={{
              ...(islogs
                ? { m: 3 }
                : el.role === "user"
                  ? styles.cardUser
                  : styles.cardModel),
              direction: cardRTL ? "rtl" : "ltr",
              textAlign: cardRTL ? "right" : "left",
            }}
          >
            <Box position="relative" display="flex" flexDirection="column">
              <Box position="absolute" top={0} left={0} p={0}>
                {el.role === "user" ? (
                  <PermIdentityTwoToneIcon />
                ) : (
                  <SmartToyTwoToneIcon />
                )}
              </Box>
              <CardContent>
                <ReactMarkdown
                  remarkPlugins={[remarkMath]}
                  rehypePlugins={[rehypeKatex, rehypeRaw]}
                  components={{
                    img: (props) => <MarkdownImage {...props} setEnlargedImage={setEnlargedImage} />,
                    // Add custom heading components to reduce level
                    h1: ({ children }) => <h2>{children}</h2>,
                    h2: ({ children }) => <h3>{children}</h3>,
                    h3: ({ children }) => <h4>{children}</h4>,
                  }}
                >
                  {el.content}
                </ReactMarkdown>
              </CardContent>
            </Box>
            {el.role === "system" && (
              <>
                <Divider sx={{ color: "red", height: "3px" }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    my: 2,
                    direction: cardRTL ? "rtl" : "ltr",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" sx={{ mx: 2 }}>
                      אנמנזה מהירה: איך הייתה התשובה?
                    </Typography>
                    <IconButton
                      onClick={() => handleFeedback(index, true)}
                      sx={{
                        mx: 1,
                      }}
                      disabled={feedbackGiven[index] !== undefined}
                    >
                      <ThumbUpAltIcon
                        sx={{
                          border: "0.5px solid black",
                          px: 1,
                          py: 0.2,
                          color:
                            feedbackGiven[index] === true
                              ? activeColor
                              : inactiveColor,
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleFeedback(index, false)}
                      sx={{
                        mx: 1,
                      }}
                      disabled={feedbackGiven[index] !== undefined}
                    >
                      <ThumbDownAltIcon
                        sx={{
                          border: "0.5px solid black",
                          px: 1,
                          py: 0.2,
                          color:
                            feedbackGiven[index] === false
                              ? activeColor
                              : inactiveColor,
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </>
            )}
          </Card>
        </React.Fragment>
      ))}
      {enlargedImage && (

        <ImageModal
          image={enlargedImage}
          open={!!enlargedImage}
          onClose={() => setEnlargedImage(null)}
        />
      )}
    </Box>

  );
};

export default ConversationHistory;

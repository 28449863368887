import { ContentNode } from "./Markdown";
import { Box, Typography, IconButton, Collapse, Tooltip, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import HierarchicalContent from "./HierarchicalContent";
import Paragraph from "./Paragraph";

interface RenderNodeProps {
  node: ContentNode;
  styles: any;
  showImages: boolean;
  handleImageClick: (src: string) => void;
  toPDF?: boolean;
  depth?: number;
  handleHeaderClick: (headerId: string) => void;
  collapsedHeaders: Set<string>;
  onTimeStampClick?: (startTime: number) => void;
  showTimeStamp?: boolean;
}

const RenderNode: React.FC<RenderNodeProps> = ({
  node,
  styles,
  showImages,
  handleImageClick,
  toPDF,
  depth = 0,
  handleHeaderClick,
  collapsedHeaders,
  onTimeStampClick,
  showTimeStamp
}) => {
  const isCollapsed = collapsedHeaders.has(node.id || "");
  if (node.type === "content") {
    return (
      <Paragraph
        content={node.content as string}
        styles={styles}
        showImages={showImages}
        handleImageClick={handleImageClick}
      />
    );
  }

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (node.id) {
      handleHeaderClick(node.id);
    }
  };

  const handlePlayClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (node.timestamp && onTimeStampClick) {
      onTimeStampClick(node.timestamp.start);
    }
  };

  const formatTime = (seconds: number): string => {
    const pad = (num: number) => String(num).padStart(2, '0');
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return `${pad(h)}:${pad(m)}:${pad(s)}`;
  };

  return (
    <Box
      id={node.id}
      sx={{
        scrollMarginTop: "100px",
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: 2,
          transition: 'all 0.2s ease',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
          },
        }}
      >
        <Box
          component={node.type}
          onClick={handleClick}
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: node.children.length > 0 ? "pointer" : "default",
          }}
        >
          <Typography
            sx={{
              ...styles[node.type],
              fontWeight: 800 - (node.level || 3) * 50,
              fontSize: {
                xs: styles[node.type].fontSize * 0.9,
                sm: styles[node.type].fontSize
              },
            }}
          >
            {node.content}
          </Typography>

          {node.children.length > 0 && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
              }}
              sx={{
                color: "text.secondary",
                marginLeft: 1,
              }}
            >
              {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          )}
        </Box>
        {node.timestamp && onTimeStampClick && showTimeStamp && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 'fit-content',
              padding: { xs: 1, sm: 1.5 },
            }}
          >
            <Tooltip title="Play from this section">
              <Box
                onClick={handlePlayClick}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  cursor: 'pointer',
                  color: 'primary.main',
                  '&:hover': {
                    color: 'primary.dark',
                  },
                }}
              >
                <PlayArrowIcon
                  sx={{
                    fontSize: { xs: 20, sm: 24 },
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                    color: 'text.secondary',
                    fontFamily: 'monospace',
                  }}
                >
                  {formatTime(node.timestamp.start)}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>

      {node.children.length > 0 && (
        <Collapse in={!isCollapsed} timeout="auto">
          <Box
            sx={{
              paddingLeft: { xs: 3, sm: 4 },
              borderLeft: '1px solid',
              borderColor: 'divider',
              marginLeft: { xs: 2, sm: 3 },
            }}
          >
            <HierarchicalContent
              nodes={node.children}
              styles={styles}
              showImages={showImages}
              handleImageClick={handleImageClick}
              toPDF={toPDF}
              depth={depth + 1}
              collapsedHeaders={collapsedHeaders}
              handleHeaderClick={handleHeaderClick}
              onTimeStampClick={onTimeStampClick}
            />
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

export default RenderNode;


import ReactGA from "react-ga4";

const TRACKING_ID = "G-0KN537307H"; // Replace with your Measurement ID
ReactGA.initialize(TRACKING_ID);

// Good category examples
export const ANALYTICS_CATEGORIES = {
  CHAT: 'Chat',
  USER: 'User',
  NAVIGATION: 'Navigation',
  CONTENT: 'Content',
  ERROR: 'Error',
  PERFORMANCE: 'Performance',
  SEARCH: 'Search',
  ENGAGEMENT: 'Engagement',
  QUIZ: 'Quiz',
  PLAYER: 'Player',
} as const;

export const logPageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};

export const logEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

export const logCustomEvent = (
  category: string,
  action: string,
  params: { [key: string]: any }
) => {
  const flattenedParams = {
    ...Object.entries(params).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value instanceof Object ? JSON.stringify(value) : value
    }), {})
  };

  ReactGA.event(action, flattenedParams);
};

export const logPageViewRe = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};

export const logEventRe = (
  category: string,
  action: string,
  label?: string,
  value?: number
) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

export const logCustomEventRe = (
  category: string,
  action: string,
  params: { [key: string]: any }
) => {
  const flattenedParams = {
    category,
    action,
    ...Object.entries(params).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value instanceof Object ? JSON.stringify(value) : value
    }), {})
  };

  ReactGA.event(flattenedParams);
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
} from "@mui/material";
import { componentStyles } from "../styles";
import "katex/dist/katex.min.css";
import { Waves } from "./Waves";
import {
  BASE_DATABASE_URL,
  BASE_SITE_URL,
  LOCAL_SERVER_ADDRESS,
  SERVER_ADDRESS,
  SHARED_QUERY,
} from "../Constants/consts";
import {
  Chunk,
  FilteringStreamMessage,
  ChatMessage,
  ImageObject,
  RetrivalStreamMessage,
  ChatSession,
  MessageMetadata,
} from "../Constants/interface";
import useFetchSharedLogs from "../Hooks/useFetchSharedLogs";
import { Snackbar } from "@mui/material";
import { fetchFirstChat } from "../Hooks/FetchChat";
import ResponsiveAppBar from "../shared/AppBar";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ANALYTICS_CATEGORIES, logCustomEvent, logEvent, logPageView } from "../utils/Analytics";
import Introduction from "./Introduction";
import { ChatBox } from "./ChatBox";
import { v4 as uuidv4 } from 'uuid';
import { addFeedback, fetchChatSession, shareChat } from "./chatDatabaseUtils";


// TODO: Change the background color to the one in the design
const getBackgroundByAlignment = (alignment: string, useGroq: boolean) => {
  if (useGroq) {
    return "linear-gradient(60deg, #0e1a01 0%, #00f9a9 100%)";
  }

  switch (alignment) {
    case "short":
      // Light, energetic blue - represents quick, concise answers
      return "linear-gradient(60deg, #1d78e0 0%, #1997f7 100%)";

    case "medium":
      // Balanced, professional blue - represents comprehensive answers
      return "linear-gradient(60deg, #2C5282 0%, #4299E1 100%)";

    case "long":
      // Deep, rich blue - represents thorough, detailed answers
      return "linear-gradient(60deg, #1A365D 0%, #2B6CB0 100%)";

    default:
      return "linear-gradient(60deg, #1A365D 0%, #2B6CB0 100%)";
  }
};


export const ChatMainRag: React.FC = () => {

  // State related to chat progress and alignment
  const [alignment, setAlignment] = useState<"short" | "medium" | "long" | "documents">("documents");
  const [stage, setStage] = useState<'retrieval' | 'filtering' | 'response'>();

  // State related to user session and interaction
  const [sessionId, setSessionId] = useState<string>("");
  const [active, setActive] = useState(false);
  const [saveResponse, setSaveResponse] = useState("yes");
  const [responseReceived, setResponseReceived] = useState(false);

  // State related to UI elements
  const [cardRTL, setCardRTL] = useState(true);
  const [stopWaves, setStopWaves] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openClearConfirm, setOpenClearConfirm] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  // State related to loading and data fetching
  const [loading, setLoading] = useState(false);
  const [loadingShared, setLoadingShared] = useState(false);
  const { chats, fetchSharedLogs } = useFetchSharedLogs(BASE_DATABASE_URL, SHARED_QUERY);

  // State related to user and network information
  const [userAgent, setUserAgent] = useState<string | null>(null);
  const [ipData, setIpData] = useState<object>({});
  const [ip, setIp] = useState<string>("");

  // State related to chat content and history
  const [partialResponse, setPartialResponse] = useState("");
  const [query, setQuery] = useState("");
  const [chatSession, setChatSession] = useState<ChatSession>({
    id: uuidv4(),
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    messages: [],
    metadata: {
      ip_data: ipData,
      user_agent: userAgent,
      model: '',
      tags: [],
      labels: [],
      upvotes: 0
    }
  });

  // State related to streaming data
  const [retrievalStream, setRetrievalStream] = useState<RetrivalStreamMessage | null>(null);
  const [filteringStream, setFilteringStream] = useState<FilteringStreamMessage | null>(null);

  // State related to timers
  const [timer, setTimer] = useState(0);



  useEffect(() => {
    logPageView();
    logEvent(ANALYTICS_CATEGORIES.CHAT, "chat_loaded", "Loaded");
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        setIpData(data);
        setIp(data.city);
        setUserAgent(userAgent);
      })
      .catch((error) => console.error("Error fetching IP:", error));
  }, []);

  // useEffect(() => {
  //   // Get the shared logs
  //   fetchSharedLogs();
  // }, [fetchSharedLogs, snackbarMessage]);

  useEffect(() => {
    /* This UseEffect is used to get the chat history from the database in case the user using a shared link*/
    const fetchChat = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const chatId = searchParams.get("id");
      console.log("chat id is ", chatId);
      if (chatId) {
        setLoadingShared(true);
        try {
          const chat = await fetchChatSession(chatId);
          if (chat) {
            console.log("chat is ", chat);
            setChatSession(chat);
          }
          setLoadingShared(false);
        } catch (error) {
          console.error("Failed to fetch chat:", error);
          setLoadingShared(false);
        }
      }
    };

    fetchChat();
  }, []);

  const handleFeedback = async (index: number, isPositive: boolean) => {
    try {
      await addFeedback(chatSession.messages[index].id, chatSession.id, isPositive);
      logEvent(
        ANALYTICS_CATEGORIES.CHAT,
        "feedback_submitted",
        `${isPositive ? "positive" : "negative"}_${chatSession.messages[index].id}`
      );
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSnackbarMessage('Failed to submit feedback');
      setSnackbarOpen(true);
      logEvent(ANALYTICS_CATEGORIES.ERROR, "feedback_error", String(error));
    }
  };

  const handleClickOpen = () => {
    logEvent(ANALYTICS_CATEGORIES.CHAT, "clear_chat_dialog_opened");
    setOpenClearConfirm(true);
  };

  const handleClose = () => {
    logEvent(ANALYTICS_CATEGORIES.CHAT, "clear_chat_dialog_cancelled");
    setOpenClearConfirm(false);
  };

  const handleClearConfirm = () => {
    logEvent(ANALYTICS_CATEGORIES.CHAT, "chat_cleared", sessionId);
    clearChatHistory();
    handleClose();
  };

  // TODO: This function is used to share the chat to whatsapp, needs rewrite
  const handleShare = async () => {
    try {
      await shareChat(sessionId);
      logEvent(ANALYTICS_CATEGORIES.CHAT, "chat_shared", sessionId);
      const postUrl = `${BASE_SITE_URL}?id=${sessionId}`;

      if (window.innerWidth < 500) {
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(postUrl)}`;
        window.open(whatsappUrl, "_blank");
        logEvent(ANALYTICS_CATEGORIES.CHAT, "chat_shared_whatsapp", sessionId);
      } else {
        await copyToClipboard(postUrl);
        setSnackbarMessage(`קישור לשיחה הועתק!`);
        setSnackbarOpen(true);
        logEvent(ANALYTICS_CATEGORIES.CHAT, "chat_link_copied", sessionId);
      }
    } catch (error) {
      console.error('Error sharing chat:', error);
      setSnackbarMessage('Failed to share chat');
      setSnackbarOpen(true);
      logEvent(ANALYTICS_CATEGORIES.ERROR, "share_chat_error", String(error));
    }
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleAligmentChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    logEvent(ANALYTICS_CATEGORIES.CHAT, "answer_style_changed", newAlignment);
    setAlignment(newAlignment as "short" | "medium" | "long" | "documents");
  };

  const clearChatHistory = () => {
    logEvent(ANALYTICS_CATEGORIES.CHAT, "chat_cleared", sessionId);
    setChatSession(prev => ({
      ...prev,
      messages: []
    }));
  };

  function handleQuerySubmit() {
    setLoading(true);
    setActive(true);
    logCustomEvent(ANALYTICS_CATEGORIES.CHAT, "query_submitted", { 'query': query, 'alignment': alignment });
    sendAndGetResponse();
  }

  const styles = componentStyles;

  function changeServerAddress(url: string): string {
    const urlParams = new URLSearchParams(new URL(url).search);
    let serverAddress = SERVER_ADDRESS;

    if (urlParams.get("local") === "true") {
      serverAddress = LOCAL_SERVER_ADDRESS;
    }

    return serverAddress;
  }

  const ServerAddress = changeServerAddress(window.location.href);

  async function generateResponse(prompt: string, options: any) {
    const params = new URLSearchParams({
      prompt: prompt,
      answerStyle: options.answerStyle ?? 'short',
      model: options.model ?? '',
      sharedFromId: options.sharedFromId ?? 'not shared',
      sessionId: options.sessionId ?? '',
      ipData: JSON.stringify(ipData),
      userAgent: userAgent ?? '',
      useLectures: alignment == 'documents' ? 'True' : 'False'
    });

    let fullResponse = '';
    try {
      logEvent(ANALYTICS_CATEGORIES.CHAT, "generate_response_start", prompt);
      const response: Response = await fetch(`${ServerAddress}/generate_response?${params}`, {
        headers: {
          'Accept': 'text/event-stream'
        }
      });

      if (!response.ok) {
        logEvent(ANALYTICS_CATEGORIES.ERROR, "generate_response_http_error", String(response.status));
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (reader) {
        try {
          const { value, done } = await reader.read();
          if (done) {
            setResponseReceived(true);
            setLoading(false);
            setActive(false);
            setPartialResponse(fullResponse);
            logCustomEvent(ANALYTICS_CATEGORIES.CHAT, "generate_response_complete", { 'answer': fullResponse, 'length': fullResponse.length });
            break;
          }

          buffer += decoder.decode(value, { stream: true });
          const messages = buffer.split('\n\n');
          buffer = messages.pop() || '';

          for (const message of messages) {
            if (message.startsWith('data: ')) {
              try {
                const data = JSON.parse(message.slice(6));
                switch (data.type) {
                  case 'session_id':
                    setSessionId(data.session_id);
                    logEvent(ANALYTICS_CATEGORIES.CHAT, "session_id_received", data.session_id);
                    break;
                  case 'metadata':
                    if (data.stage === 'retrieval') {
                      handleInitialChunks(data.chunks);
                      logEvent(ANALYTICS_CATEGORIES.CHAT, "retrieval_chunks_received", String(data.chunks.length));
                    } else if (data.stage === 'filtering') {
                      handleFilteredChunks(data.chunks, data.images);
                      logEvent(ANALYTICS_CATEGORIES.CHAT, "filtered_chunks_received", String(data.chunks.length));
                    }
                    break;
                  case 'response':
                    handleResponseChunk(data.content);
                    fullResponse += data.content;
                    break;
                  case 'error':
                    handleError(data.content);
                    logEvent(ANALYTICS_CATEGORIES.ERROR, "generate_response_error", data.content);
                    setLoading(false);
                    setActive(false);
                    break;
                }
              } catch (e) {
                console.error('Error parsing chunk:', e);
                logEvent(ANALYTICS_CATEGORIES.ERROR, "parse_chunk_error", String(e));
              }
            }
          }
        } catch (error) {
          console.error('Error reading stream:', error);
          logEvent(ANALYTICS_CATEGORIES.ERROR, "read_stream_error", String(error));
          setLoading(false);
          setActive(false);
          break;
        }
      }
    } catch (error) {
      logEvent(ANALYTICS_CATEGORIES.ERROR, "generate_response_error", String(error));
      throw error;
    }

    return fullResponse;
  }

  const handleInitialChunks = (chunks: Chunk[]) => {
    console.log('Retrieved initial chunks:', chunks.length);
    setStage('retrieval');
    setRetrievalStream({
      type: 'metadata',
      stage: 'retrieval',
      chunks: chunks
    });
  };

  const handleFilteredChunks = (chunks: Chunk[], images: ImageObject[]) => {
    setStage('filtering');
    setFilteringStream({
      type: 'metadata',
      stage: 'filtering',
      chunks: chunks,
      images: images
    });
  };

  const handleResponseChunk = (content: string) => {
    console.log('Response chunk:', content);
    setStage('response');
    setPartialResponse((prevResponse) => prevResponse + content);
  };

  const handleError = (error: any) => {
    console.error('Error:', error);
    logEvent(ANALYTICS_CATEGORIES.ERROR, "response_error", String(error));
  };

  const sendAndGetResponse = async () => {
    try {
      setChatSession(prev => ({
        ...prev,
        messages: [
          ...prev.messages,
          { role: "user", content: query, id: uuidv4() }
        ]
      }));
      const fullResponse = await generateResponse(query, {
        answerStyle: alignment,
        saveResponse: saveResponse,
        sessionId: sessionId,
        model: "gemini/gemini-2.0-flash-exp"
      });
      console.log("stream is complete ", fullResponse);

      setChatSession(prev => ({
        ...prev,
        messages: [
          ...prev.messages,
          { role: 'system', content: fullResponse, id: uuidv4() }
        ]
      }));
      setPartialResponse("");
      setQuery("");
    } catch (error) {
      logEvent(ANALYTICS_CATEGORIES.CHAT, "response_error", error as string);
      console.error("Error in generating response:", error);
      setSnackbarMessage("Error generating response. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setResponseReceived(true);
    }
  };

  const copyToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    } else {
      // Clipboard API not available, you may want to fallback to a more manual method
      console.log("Clipboard API not available");
    }
  };

  return (
    <>
      <Box
        sx={{
          ...styles.mainBox,
          background: getBackgroundByAlignment(alignment, false),
          transition: "background 1.5s ease",
        }}
      >
        <ResponsiveAppBar />
        <Box
          sx={{
            ...styles.innerBox,
          }}
        >
          {/* <ChatDrawer
            userUpvotes={userUpvotes}
            addToUpvote={addToUpvote}
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            chats={chats}
            handleChatLoad={handleChatLoad}
          /> */}

          <Introduction />

          {loadingShared && (
            <Box
              sx={{
                position: "fixed", // Use fixed to keep it centered regardless of scrolling
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 5000,
              }}
            >
              <CircularProgress color="warning" />
            </Box>
          )}

          <ChatBox
            loading={loading}
            active={active}
            partialResponse={partialResponse}
            chatSession={chatSession}
            cardRTL={cardRTL}
            alignment={alignment}
            query={query}
            sessionId={sessionId}
            handleFeedback={handleFeedback}
            handleAligmentChange={handleAligmentChange}
            handleShare={handleShare}
            handleClickOpen={handleClickOpen}
            setTimer={setTimer}
            onQueryChange={handleQueryChange}
            onQuerySubmit={handleQuerySubmit}
            filteringStream={filteringStream}
            stage={stage}
          />

          <Dialog
            open={openClearConfirm}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{ direction: "rtl" }} id="alert-dialog-title">
              {"בטוח שאתה רוצה לנקות?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{ direction: "rtl" }}
                id="alert-dialog-description"
              >
                אחרי שתעשה דבר כזה, אין לי איך לעזור ולהחזיר את הצ'אט שלך
              </DialogContentText>
              <DialogContentText
                sx={{ direction: "rtl" }}
                id="alert-dialog-description"
              >
                במידה שענת צ'אט חיצוני, הוא לא יעלם אבל והשינויים שביצעת לא
                ישמרו ו.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClearConfirm} autoFocus>
                נקה צ'אט
              </Button>
              <Button onClick={handleClose}>בטל</Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            sx={{ direction: "rtl", zIndex: 9999 }}
            open={snackbarOpen}
            autoHideDuration={2500}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          />
        </Box>
        <Waves
          waveSpeed={
            stopWaves
              ? 0
              : alignment === "short"
                ? 3.5
                : alignment === "medium"
                  ? 6
                  : 9
          }
        />
        <Fab
          color="primary"
          aria-label="speed"
          size="small"
          sx={{
            position: "fixed",
            opacity: 0.3,
            bottom: 8, // Reduced from 10
            left: 8, // Reduced from 10
            width: 25, // Added explicit small width
            height: 25, // Added explicit small height
            minHeight: "unset", // Override default minimum height
            "& .MuiSvgIcon-root": {
              fontSize: "1rem", // Smaller icon
            },
            "&:hover": {
              opacity: 0.6,
            },
          }}
          onClick={() => {
            setCardRTL((prev) => !prev);
          }}
        >
          {cardRTL ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
        </Fab>
        <Fab
          size="small"
          color="primary"
          aria-label="speed"
          sx={{
            position: "fixed",
            opacity: 0.7,
            bottom: 8, // Reduced from 10
            left: 40, // Adjusted from 60 to maintain proportional spacing
            width: 25, // Added explicit small width
            height: 25, // Added explicit small height
            minHeight: "unset", // Override default minimum height
            "& .MuiSvgIcon-root": {
              fontSize: "1rem", // Smaller icon
            },
            "&:hover": {
              opacity: 0.9,
            },
          }}
          onClick={() => {
            setStopWaves((prev) => !prev);
          }}
        >
          {stopWaves ? <PlayArrowIcon /> : <PauseIcon />}
        </Fab>
      </Box>
    </>
  );
};

export default ChatMainRag;

import { Pass } from "three/examples/jsm/postprocessing/Pass";
import {
  BASE_DATABASE_URL,
  QUREIES,
  WEBSITE,
  getDatabaseEndpoint,
} from "../Constants/consts";

export async function addPostToDatabase(
  data: object,
  collection: string,
  database: string
) {
  try {
    const workerEndpoint = getDatabaseEndpoint(collection, database);
    const response = await fetch(workerEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    console.log("response", response);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (response.status === 400) {
      return "This post is unavailable. Try another!.";
    }
  } catch (error) {
    console.error(("Error sending data to the worker:" + error) as string);
  }
}


export async function addPostToShare(
  data: object,
  collection: string,
  database: string
) {
  try {
    const workerEndpoint = getDatabaseEndpoint(collection, database);
    const response = await fetch(workerEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (response.status === 400) {
      return "This post is unavailable. Try another!.";
    }
  } catch (error) {
    console.error(("Error sending data to the worker:" + error) as string);
  }
}
//https://database-handling.azurewebsites.net/api/update_post
export async function addBookmarkForUser(
  dataToAdd: object,
  collection: string,
  database: string,
  itemId: number
) {
  try {
    const databaseEndpoint = `${BASE_DATABASE_URL}update_post?database=${database}&container=${collection}&itemId=${itemId}`;
    const response = await fetch(databaseEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToAdd),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (response.status === 400) {
      return "This post is unavailable. Try another!.";
    }
    if (response.status === 200) {
      console.log("pushed data successfuly, the response is", response.json());
    }
  } catch (error) {
    console.error(("Error sending data to the worker:" + error) as string);
  }
}

import Footer from "./shared/Footer";
import { Box } from "@mui/material";
import { ChatMain } from "./chat/ChatMain";
import { useEffect, useState } from "react";
import CalendarComponent from "./CalendarComponent";
import ChatMainRag from "./chat/ChatMainRag";

export default function Blog() {
  const [isCalender, setIsCalender] = useState(false);
  const [goBack, setGoBack] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isCalender = searchParams.get("c");
    console.log(isCalender);

    if (isCalender == "calendar" && !goBack) {
      setIsCalender(true);
    }
  });
  return (
    <>
      <Box>
        <Box>
          <ChatMainRag />
        </Box>
        <Footer
          title="מוזמנים ליצור קשר ! "
          description="אני זמין במייל yosefbyd@gmail.com"
        />
      </Box>
    </>
  );
}

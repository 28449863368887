import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Lecture } from "../Constants/interface";
import { Typography, Paper, Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ANALYTICS_CATEGORIES, logCustomEvent } from "../utils/Analytics";
import { alpha } from "@mui/material/styles";
import {
  API_BASE_URL,
  fetchLectureBasicInfo,
} from "./Services/firebaseService";
import { SearchTextField } from "./StyledPaper";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

interface SelectLectureProps {
  metadata: Lecture[] | string[];
  setLecture: React.Dispatch<React.SetStateAction<Lecture | null>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isTestMode: boolean;
  preSelectedLectureId?: string;
  additionalYearsToShow?: string[];
}
const commonTypos: Record<string, string> = {
  "משפט משפט": "משפט",
};
const yearVals = {
  "1": `שנה א'`,
  "2": `שנה ב'`,
  "3": `שנה ג'`,
  "4": `שנה ד'`,
  '-2': `שנהב'`,
  '-3': 'גאיה'
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  background: alpha(theme.palette.common.white, 0.8),
  borderRadius: 20,
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.15)",
  direction: "rtl",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0 12px 40px rgba(0, 0, 0, 0.2)",
  },
}));

interface GroupedData {
  [year: string]: {
    [subject: string]: {
      [subFolder: string]: Lecture[];
      noSubFolder: Lecture[];
    };
  };
}

const sortByAlphabet = (a: string, b: string): number => {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
};

const sortLectures = (a: Lecture, b: Lecture): number => {
  return a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

const groupByYear = (metadata: Lecture[], additionalYearsToShow: string[] = []): GroupedData => {
  return metadata.reduce((acc: GroupedData, lecture) => {
    if (lecture && lecture.subject && lecture.name) {
      const year = lecture.year ?? "unknown";
      // Include standard years (1-4) or any additional years specified
      if (
        year === "1" ||
        year === "2" ||
        year === "3" ||
        year === "4" ||
        additionalYearsToShow.includes(year)
      ) {
        if (!acc[year]) {
          acc[year] = {};
        }
        if (!acc[year][lecture.subject]) {
          acc[year][lecture.subject] = {
            noSubFolder: [],
          };
        }

        Object.keys(commonTypos).forEach((typo) => {
          if (lecture.name.includes(typo)) {
            lecture.name = lecture.name.replace(typo, commonTypos[typo]);
          }
        });

        // Group by subFolder if it exists
        if (lecture.subFolder) {
          if (!acc[year][lecture.subject][lecture.subFolder]) {
            acc[year][lecture.subject][lecture.subFolder] = [];
          }
          acc[year][lecture.subject][lecture.subFolder].push(lecture);
        } else {
          acc[year][lecture.subject].noSubFolder.push(lecture);
        }
      }
    }
    return acc;
  }, {});
};

interface TreeItemData {
  id: string;
  level: number;
  label: string;
}

interface FilteredData {
  [year: string]: {
    [subject: string]: {
      [subFolder: string]: Lecture[];
      noSubFolder: Lecture[];
    };
  };
}

const renderSearchResults = (lectures: Lecture[]) => {
  return lectures
    .sort(sortLectures)
    .map((lecture) => (
      <TreeItem
        key={lecture.id}
        itemId={`${lecture.id}|2`}
        label={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant="body1" sx={{ fontWeight: 500, color: 'rgba(0, 0, 0, 0.9)' }}>
              {lecture.name}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: (theme) => alpha(theme.palette.common.black, 0.6),
                fontSize: '0.75rem'
              }}
            >
              {`${yearVals[lecture.year as keyof typeof yearVals]} | ${lecture.subject}${lecture.subFolder ? ` | ${lecture.subFolder}` : ''}`}
            </Typography>
          </Box>
        }
        sx={{
          marginBottom: 1,
          '& .MuiTreeItem-content': {
            padding: '8px 12px',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }
        }}
      />
    ));
};

export const SelectLecture = ({
  metadata,
  setLecture,
  setIsLoading,
  isTestMode,
  preSelectedLectureId,
}: SelectLectureProps) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const theme = useTheme();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Parse URL query parameters to determine additional years
  const additionalYearsToShow = React.useMemo(() => {
    const years: string[] = [];
    if (searchParams.has("gaya")) years.push("-3");
    if (searchParams.has("shenhav")) years.push("-2");
    if (searchParams.has("wolfson")) years.push("-10");

    return years;
  }, [searchParams]);

  // Filter out everything that isn't year = "-3" if ?gaya was supplied
  const filteredMetadata = React.useMemo(() => {
    if (!isTestMode && additionalYearsToShow.length > 0) {
      return (metadata as Lecture[]).filter(
        (lecture) =>
          additionalYearsToShow.includes(lecture.year || "")
      );
    }
    return metadata;
  }, [metadata, additionalYearsToShow, isTestMode]);

  useEffect(() => {
    if (preSelectedLectureId) {
      handleSelectedItemsChange({} as React.SyntheticEvent, `${preSelectedLectureId}|2`);
    }
  }, [preSelectedLectureId]);

  const handleSelectedItemsChange = async (
    event: React.SyntheticEvent,
    itemIds: string | null
  ) => {
    setSelectedItem(itemIds);

    if (!itemIds) return;

    const [id, level] = itemIds.split("|");

    if (level === "2") {
      try {
        setIsLoading(true);
        const lecture = await fetchLectureBasicInfo(id);
        const initialLecture = {
          ...lecture,
          AudioSrc: `${API_BASE_URL}/audio/${lecture.audioFileId}`,
        };

        setLecture(initialLecture);

        logCustomEvent(ANALYTICS_CATEGORIES.CONTENT, "lecture_selected", {
          lecture: lecture.name,
          subject: lecture.subject,
          subfolder: lecture.subFolder ?? "",
        });
      } catch (error) {
        console.error("Error fetching lecture data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getColor = (depth: number): string => {
    switch (depth) {
      case 0:
        return theme.palette.common.black;
      case 1:
        return alpha(theme.palette.common.black, 0.85);
      default:
        return alpha(theme.palette.common.black, 0.8);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const renderTreeItems = () => {
    if (isTestMode) {
      const filteredFiles = (filteredMetadata as string[]).filter((filename) =>
        filename.toLowerCase().includes(searchQuery.toLowerCase())
      );

      return filteredFiles
        .sort(sortByAlphabet)
        .map((filename, index) => (
          <TreeItem
            key={`${filename}-${index}`}
            itemId={`${filename}|2`}
            label={filename}
          />
        ));
    } else {
      // If there's a search query, show flat list
      if (searchQuery) {
        const filteredLectures = (filteredMetadata as Lecture[]).filter((lecture) => {
          const searchString = `${lecture.name} ${lecture.subject} ${lecture.subFolder || ''}`.toLowerCase();
          return searchString.includes(searchQuery.toLowerCase());
        });

        return renderSearchResults(filteredLectures);
      }

      // Otherwise show the tree structure
      const groupedByYear = groupByYear(filteredMetadata as Lecture[], additionalYearsToShow);
      return Object.entries(groupedByYear)
        .sort(([a], [b]) => sortByAlphabet(a, b))
        .map(([year, subjects]) => (
          <TreeItem
            key={year}
            itemId={`${year}|0`}
            label={yearVals[year as keyof typeof yearVals] || year}
            sx={{ color: getColor(0), marginRight: 1 }}
          >
            {Object.entries(subjects)
              .sort(([a], [b]) => sortByAlphabet(a, b))
              .map(([subject, subFolders]) => (
                <TreeItem
                  key={`${year}-${subject}`}
                  itemId={`${subject}|1`}
                  label={subject}
                  sx={{ color: getColor(1), marginRight: 1.4 }}
                >
                  {Object.entries(subFolders)
                    .sort(([a], [b]) => {
                      // Keep 'noSubFolder' entries at the end
                      if (a === "noSubFolder") return 1;
                      if (b === "noSubFolder") return -1;
                      return sortByAlphabet(a, b);
                    })
                    .map(([folder, lectures]) => {
                      // Skip empty folders
                      if (lectures.length === 0) return null;

                      // For lectures without subFolder
                      if (folder === "noSubFolder") {
                        return lectures
                          .sort(sortLectures)
                          .map((lecture: Lecture) => (
                            <TreeItem
                              key={lecture.id}
                              itemId={`${lecture.id}|2`}
                              label={lecture.name}
                              sx={{ color: getColor(2), marginRight: 2.1 }}
                            />
                          ));
                      }

                      // For lectures with subFolder
                      return (
                        <TreeItem
                          key={`${year}-${subject}-${folder}`}
                          itemId={`${folder}|1.5`}
                          label={folder}
                          sx={{ color: getColor(1.5), marginRight: 1.8 }}
                        >
                          {lectures
                            .sort(sortLectures)
                            .map((lecture: Lecture) => (
                              <TreeItem
                                key={lecture.id}
                                itemId={`${lecture.id}|2`}
                                label={lecture.name}
                                sx={{ color: getColor(2), marginRight: 2.4 }}
                              />
                            ))}
                        </TreeItem>
                      );
                    })}
                </TreeItem>
              ))}
          </TreeItem>
        ));
    }
  };

  return (
    <StyledPaper elevation={3}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: theme.palette.common.black,
          fontWeight: 500,
          letterSpacing: "0.5px",
          textShadow: "0 2px 4px rgba(0,0,0,0.2)",
          borderBottom: "2px solid rgba(255, 255, 255, 0.1)",
          paddingBottom: 1,
          marginBottom: 2,
        }}
      >
        בחר קורס והרצאה
      </Typography>

      <SearchTextField
        placeholder="חפש לפי שם הרצאה, קורס או נושא..."
        value={searchQuery}
        onChange={handleSearchChange}
        variant="outlined"
        size="small"
        sx={{
          color: "rgba(0, 0, 0, 0.7)",
          mb: 2,
          '& .MuiOutlinedInput-root': {
            color: 'rgba(0, 0, 0, 0.9)',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '12px',
            transition: 'all 0.2s ease',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',

            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.15)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.3)',
            },
            '&.Mui-focused': {
              backgroundColor: 'rgba(255, 255, 255, 1)',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.5)',
                borderWidth: '2px',
              },
            },
          },
          '& .MuiInputLabel-root': {
            color: 'rgba(0, 0, 0, 0.7)',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.9)',
          },
          '& .MuiInputBase-input': {
            '&::placeholder': {
              color: 'rgba(0, 0, 0, 0.5)',
              opacity: 1,
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          minHeight: 200,
          maxHeight: 400,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0.2)",
            borderRadius: "10px",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.3)",
            },
          },
        }}
      >
        <SimpleTreeView
          selectedItems={selectedItem}
          onSelectedItemsChange={handleSelectedItemsChange}
          multiSelect={false}
          slots={{
            expandIcon: ChevronRightIcon,
            collapseIcon: ExpandMoreIcon,
          }}
          sx={{
            color: "rgba(255, 255, 255, 0.9)",
            "& .MuiTreeItem-content": {
              padding: "4px 8px",
              borderRadius: "8px",
              transition: "all 0.2s ease",
              "&:hover": {
                background: "rgba(255, 255, 255, 0.1)",
              },
              "&.Mui-selected": {
                background: "rgba(255, 255, 255, 0.15) !important",
              },
            },
            "& .MuiTreeItem-label": {
              fontSize: "0.95rem",
              fontWeight: 400,
            },
            "& .MuiTreeItem-group": {
              marginLeft: "17px",
              paddingLeft: "18px",
              borderLeft: "1px dashed rgba(255, 255, 255, 0.2)",
            },
          }}
        >
          {renderTreeItems()}
        </SimpleTreeView>
      </Box>
    </StyledPaper>
  );
};

export default SelectLecture;

// @ts-nocheck
import { useState, useCallback } from 'react';
import { ChatMessage, SharedLogEntry, Chat } from '../Constants/interface';

const useFetchSharedLogs = (baseUrl: string, query: string) => {
    const [chats, setChats] = useState<Chat[]>([]);

    const fetchSharedLogs = useCallback(() => {
        const url = `${baseUrl}/get_posts?container=Chats&query=${encodeURIComponent(query)}`;
        fetch(url)
            .then((response) => response.json())
            .then((data: SharedLogEntry[]) => {
                const formattedChats = data.map((log) => {
                    let chatHistory = log.chatHistory;
                    if (typeof chatHistory === 'string') {
                        chatHistory = JSON.parse(chatHistory);
                    }
                    return (
                        {
                            id: log.id,
                            headline: log.headline,
                            upvotes: log.upvotes,
                            tags: log.tags,
                            labels: log.labels,
                            shared: log.shared,
                            sessionId: log.sessionId,
                            metadata: log.metadata,
                            chatHistory: chatHistory.map((item: ChatMessage) => ({
                                role: item.role,
                                content: item.content,
                                definition: item.definitions ?? ""
                            }))
                        }
                    );
                });
                setChats(formattedChats);
            })
            .catch((error) => console.error('Error fetching logs:', error));
    }, [baseUrl, query]);

    return { chats, fetchSharedLogs };
};

export default useFetchSharedLogs;